.pi-ck {
  width: 100% !important;
  border-radius: 30px !important;
  height: 50px !important;
}
.suggst-btn button {
  padding: 0.25rem !important;
}
.button-01 {
  background-color: #0353a4;
  color: white;
  border: transparent;
  font-size: 12px;
  width: 84px;
  height: 25px;
}
.picture {
  width: 71% !important;
  border-radius: 31px !important;
  height: 56px !important;
}
.text-area {
  width: 98%;
  height: 17vh;
  border: transparent;
  background-color: beige;
}
.PHERA-0 {
  font-size: 12px;
}
.PHERA {
  font-size: 13px;
}
.scrll {
  height: 46vh !important;
  overflow-y: auto !important;
  overflow-x: hidden;
}
.scrll1 {
  height: 82vh !important;
  overflow-y: auto !important;
  overflow-x: hidden;
}
.btn3 {
  width: 80px;
  height: 25px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 13px;
  background-color: rgba(3, 83, 164, 1);
  color: white;
  border: transparent;
}
