/* .select-option {
  border: 1px solid #b3b3b3 !important;
  box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px;
  color: #444444;
  font-size: 13px;
  height: 36px;
} */

.arrowback {
  background-color: #0353a4 !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.btn1 {
  background-color: white !important;
  color: red;
  width: 80px;
  height: 25px;
  margin-right: 3vh;
  border: 2px solid red;
  border-radius: 5px;
  font-weight: 700;
  font-size: 13px;
}

.btn1:hover {
  background-color: red !important;
  color: white;
}

.cbtn1 {
  background-color: white !important;
  color: red;
  width: 120px;
  height: 36px;
  margin-right: 4vh;
  border: 2px solid red;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
}

.cbtn1:hover {
  background-color: red !important;
  color: white;
}

.btn2 {
  width: 80px;
  height: 25px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 13px;
  color: white;
  background-color: #0353a4;
  border: 1px solid #0353a4;
}

.upload-img {
  width: 100%;
  height: 90px;
  object-fit: cover;
  column-gap: 10px;
  /* margin-top: 8px;
  border: 2px solid blue; */
}

.close-btnone {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: #000000;
}

.iMg {
  width: 18px;
  position: absolute;
  right: 0;
  bottom: 6px;
}

.bluedrop8 {
  position: relative;
}

.bluedrop8::after {
  content: "\f078";
  font: normal normal normal 17px / 1 FontAwesome;
  right: 1px !important;
  top: 33px !important;
  padding: 5px 12px 8px 11px;
  position: absolute;
  pointer-events: none;
  background-color: #0353a4;
  border-radius: 4px;
  color: white;
}

.bluedrop4 {
  position: relative;
}

.bluedrop4::after {
  content: "\f078";
  font: normal normal normal 17px / 1 FontAwesome;
  right: 13px !important;
  top: 41px !important;
  padding: 5px 12px 8px 11px;
  position: absolute;
  pointer-events: none;
  background-color: #0353a4;
  border-radius: 6px;
  color: white;
}

.bluedrop3 {
  position: relative;
}

.bluedrop3::after {
  content: "\f078";
  font: normal normal normal 17px / 1 FontAwesome;
  right: 10px !important;
  top: 31px !important;
  padding: 15px 14px 13px 13px;
  position: absolute;
  pointer-events: none;
  background-color: #0353a4;
  border-radius: 6px;
  color: white;
}

.bluedrop2 {
  position: relative;
}

.bluedrop2::after {
  content: "\f078";
  font: normal normal normal 17px / 1 FontAwesome;
  right: 0px !important;
  top: 43px !important;
  padding: 9px 11px 10px 11px;
  position: absolute;
  pointer-events: none;
  background-color: #0353a4;
  border-radius: 6px;
  color: white;
}

.bblue:focus {
  outline: none;
}

.bluedrop {
  position: relative !important;
}

.bluedrop::after {
  content: "\f078";
  font: normal normal normal 17px/1 FontAwesome;
  right: 0px !important;
  top: 0px !important;
  /* height: 34px; */
  padding: 15px 14px 13px 13px;
  position: absolute;
  pointer-events: none;
  background-color: #0353a4;
  border-radius: 6px;
  color: white;
}

.bluedrop5 {
  position: relative !important;
}

.bluedrop5::after {
  content: "\f078";
  font: normal normal normal 17px/1 FontAwesome;
  right: 13px !important;
  top: 31px !important;
  padding: 15px 14px 13px 13px;
  position: absolute;
  pointer-events: none;
  background-color: #0353a4;
  border-radius: 6px;
  color: white;
}

.bluedrop6 {
  position: relative !important;
}

.bluedrop6::after {
  content: "\f078";
  font: normal normal normal 17px/1 FontAwesome;
  right: 12px !important;
  top: 41px !important;
  padding: 15px 14px 13px 13px;
  position: absolute;
  pointer-events: none;
  background-color: #0353a4;
  border-radius: 6px;
  color: white;
}

.selectdiv {
  position: relative;
  float: left;
  /* min-width: 200px; */
  min-width: fit-content;
  border: 1px solid #8080809e;
  border-radius: 8px;
  box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.25) !important;
  padding: 6px;
}

.selectdiv:after {
  content: "\f078";
  font: normal normal normal 17px/1 FontAwesome;
  right: 0px !important;
  top: 0px !important;
  /* height: 34px; */
  padding: 10px 10px 9px 11px;
  position: absolute;
  pointer-events: none;
  background-color: #0353a4;
  border-radius: 6px;
  color: white;
}

select::-ms-expand {
  display: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 197px;
  display: block;
  /* float: right;
  margin: 5px 0px;
  border: 1px solid #b3b3b3 !important;
  box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.25) !important; */
  /* border-radius: 8px; */
  color: #444444;
  font-size: 13px;
  /* height: 38px !important; */
  padding: 0px 0px !important;
  padding-left: 10px !important;
  margin-top: 0px !important;
  border: none !important;
  outline: none !important;
}

/* @media (max-width: 855px) and (min-width: 700px) {
  .select-option {
    font-size: 13px;
    height: 38px;
  }
}
@media (max-width: 691px) and (min-width: 592px) {
  .select-option {
    font-size: 13px;
    height: 34px;
  }
} */

/* /////////////////small device//////////////// */

@media (width >=320px) and (width <=399px) {
  .selectdiv select {
    width: 326px !important;
  }
}


@media (width >=400px) and (width <=484px) {
  .selectdiv select {
    width: 410px !important;
  }
}

@media (width >=485px) and (width <=559px) {
  .selectdiv select {
    width: 482px !important;
  }
}


@media (width >=560px) and (width <=575px) {
  .selectdiv select {
    width: 503px !important;
  }
}

@media (width >=576px) and (width <=599px) {
  .selectdiv select {
    width: 350px;
  }
}

@media (width >=600px) and (width <=633px) {
  .selectdiv select {
    width: 376px;
  }
}



@media (width >=634px) and (width <=664px) {
  .selectdiv select {
    width: 400px;
  }
}



@media (width >=665px) and (width <=697px) {
  .selectdiv select {
    width: 423px;
  }
}

@media (width >=698px) and (width <=726px) {
  .selectdiv select {
    width: 450px;
  }

}

@media (width >=727px) and (width <=767px) {
  .selectdiv select {
    width: 471px;
  }

}

@media (width >=768px) and (width <=830px) {
  .selectdiv select {
    width: 172px;
  }
}

@media (width >=961px) and (width <=991px) {
  .selectdiv {
    min-width: 132px;
  }

  .selectdiv select {
    width: 227px !important;
    padding-left: 0% !important;
  }
}

@media (width >=992px) and (width <=1098px) {
  .selectdiv select {
    width: 213px !important;
  }
}

@media (width>=1120px) and (width<=1178px) {
  .selectdiv select {
    width: 230px !important;
  }

  .selectdiv {
    min-width: 170px;
  }
}

@media (width >=1178px) and (width <1210px) {
  .selectdiv select {
    width: 240px !important;
  }

  .selectdiv {
    min-width: 190px;
  }
}

@media (width >=1210px) and (width <1250px) {
  .selectdiv select {
    width: 250px !important;
  }

  .selectdiv {
    min-width: 190px;
  }
}

@media (width >=1250px) and (width <=1398px) {
  .selectdiv select {
    width: 285px !important;
  }
}

/* /////////////////large device//////////////// */
@media (width >=1399px) {
  .selectdiv select {
    width: 289px;
  }
}

@media (width=1400px) {
  .selectdiv select {
    width: 289px;
  }
}

@media (width > 1400px) {
  .selectdiv select {
    width: 295px;
  }
}