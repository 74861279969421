.offerButton {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 10px 3px #E7E8FB;
    border-radius: 8px;
    height: 116px;
    width: 86%!important;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .fw-lighter {
        font-weight: lighter!important;
        font-size: 12px!important;
    }
    .shop-profile-img {
        height: 155px!important;
    }
    .font-sm {
        font-size: 9px!important;
    }
    .tab-font{
        font-size: 13px!important;
    }
    .candidateOccupation {
        font-size: 10px!important;
    }
    .connect-btn {
        padding: 0px 17px 0px 17px !important;
    }
    .jobPara {
        font-size: 7px!important;
    }
    .jobsDesc{
        font-size: 10px;
    }
}
@media only screen and (min-width:768px) and (max-width:991px){
    .candidateImg {
        height: 46px!important;
        width: 46px!important;
    }
    .candidateName {
        font-size: 11px!important;
    }
    .candidateOccupation {
        font-size: 8px!important;
    }
    .tab-font{
        font-size: 13px!important;
    }
    .jobPara {
        font-size: 7px!important;
    }
    .jobsDesc{
        font-size: 10px;
    }
    .connect-btn {
        padding: 0px 10px 0px 10px!important;
        font-size: 8px!important;
    }
    .pagination {
        --bs-pagination-padding-x: 0.4rem!important;
    }
}
@media only screen and (min-width:511px) and (max-width:767px){
    .jobPara {
        font-size: 10px!important;
    }
    .jobsDesc{
        font-size: 10px!important;
    }
    .w-img {
        width: 17px!important;
        height: 21px!important;
    }
    .pagination {
        --bs-pagination-padding-x: 0.4rem!important;
    }
}
@media only screen and (min-width:414px) and (max-width:510px){
    .jobPara {
        font-size: 8px!important;
    }
    .jobsDesc{
        font-size: 8px!important;
    }
    .pagination {
        --bs-pagination-padding-x: 0.4rem!important;
    }
}
@media only screen and (min-width:320px) and (max-width:413px){
    .jobPara {
        font-size: 6px!important;
    }
    .jobsDesc{
        font-size: 6px!important;
    }
    .pagination {
        --bs-pagination-padding-x: 0.3rem!important;
    }
}