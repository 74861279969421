.verify-input{
  -webkit-border-radius: 4px;
    -moz-border-radius: 4px ;
    border-radius: 4px ;
    border-width: 5px 0px 5px 0px;
    padding: 5px !important;   
    background: #ced4da !important;
  
}

.verify-input:focus{
  background-color:#ced4da !important ;
  border-color: #ced4da !important;
}

.verify-input::-webkit-outer-spin-button,
.verify-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.f-bg-color{

    background: #888888;
    height:100vh;

}
  .f-modal-style{
    border: 1px solid #7B7B93;
    padding: 3%;
    margin-top: 13rem !important;
  }
    .font-ss{
        font-size: 13px;
    }

    .next-btn-s .input-style{

box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);

    }

