.msg_icon{
    color:#006DAA !important;
    font-size: 3rem !important;
}

.cards_mob_p{
    padding: 3rem;
}

    .create-chatroom-btn{
        color:white;
        background: #006DAA !important;
        font-size: 14px;
        border-radius: 8px;
    }


    .custom-bg{
      
        border-radius: 8px;
        background: #212121 !important;
    }
    .custom-bg  h6{
            color:white;
            font-weight: 400;
            font-size: 13px;
        }
    
    .custom-bg-2{
        border-radius: 8px;
        background: #4B123E !important;
    }
    
    .custom-bg-2 h6{
        color:white;
            font-weight: 400;
            font-size: 13px;
    }
    .bordr-bottm{
        border-bottom: 1px solid rgb(241, 235, 235);
    }
    
    .chatroom_label{
        font-size: 11px;
        color:white;
    }
    
    .chat_chat
    .MuiIconButton-label{
        justify-content: left!important;
    }
    
    
    
    .has--search .form-control {
        padding-left: 3rem;
    }
    
    .has--search .form-control-feedback {
        position: absolute;
        z-index: 2;
        display: block;
        width: 2.375rem;
        height: 2.375rem;
        line-height: 2.375rem;
        text-align: center;
        pointer-events: none;
        color: #aaa;
    }
    
    .activechatroom{
        position: absolute;
        right:0;
    }
    .activechatroom  .dotttt{
                border-radius: 50%;
                background: #0FA958 !important;
                color: #0FA958;
                padding: 1px 6px 1px 6px;
                font-size: 10px;
            }
        
    
    
            .scroll__row{
                height:75vh;
                overflow-y: auto;
            }
        
    @media only screen and (max-width:568px){
        .mobile_colmn{
            padding-left: 2rem !important;
            padding-right: 2rem !important;
        }
    }