
    .input-container input.disabled {
    border-color: #ccc;
    cursor: not-allowed;
  }
  
  .disabled-icon {
    position: absolute;
    top: 70%;
    right: 8px;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
  }

  .exclude-number input[type="number"]::-webkit-inner-spin-button,
.exclude-number input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the increment and decrement arrows for Firefox */
.exclude-number input[type="number"] {
  -moz-appearance: textfield;
}



.cstm-list{
    --bs-list-group-bg: transparent !important;
}
.Profile-setting
{
    background: #F9F9F9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    height: 80vh;

}
.input-width-setting{
  width: 100%;
}
.scroll-education{
  height: 25vh;
  overflow-y: auto;
}
@media only screen and (max-width:991px){
    .Profile-setting{
height: auto !important;
    }
}
.custom-bullet:before {
    content: "•";
    color: #000000;
    font-weight: bold;
    display: inline-block;
    width: 20px;
    font-size: 1.5rem;
    margin-left: -1em;
  }
