.Table-font{
    font-size: 11px;
}
.TABLE {
    background-color: #267DCF;
    color: white;
}
/* .tablerow{
    width: 100%;
} */
.table-responsive .table{
width: 100%!important;
}
.headtxt{
    font-size: 13px;
}

.bg-clr-class{

    background-color: #F2F2F2;

}

.bbb{

    color: #0021F5;
    font-size: 11px;
    display: flex;
    justify-content: center;

}
@media (max-width: 991px) and (min-width: 768px) { 
    .table-responsive .table {
        width: 100%!important;
        height: 215px!important;
    }
} 

@media (max-width: 767px) and (min-width: 576px) { 
    .table-responsive .table {
        width: 100%!important;
        height: 215px!important;
    }
}

@media (max-width: 575px) and (min-width: 367px) { 
    .table-responsive .table {
        width: 100%!important;
        height: 215px!important;
    }
}