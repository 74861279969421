.smallDialogBox{
    position: absolute;
    z-index: 1;
    margin-top: 35px;

}
.select_box{
    width: 45% !important;
}
.writeinput{
    width: 98%;
}
@media only screen and (min-width:1200px) and (max-width:1399px){

    .select_box{
        width: 41.5% !important;
    }
}

