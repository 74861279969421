.deleteRed {
  color: #ee3b3b;
  font-size: 23px !important;
}

#uncontrolled-tab-example-tab-Newjobs {
  background-color: #0353a4;
  border-radius: 0px;
  color: white;
  padding: 5px 15px;
  margin-top: 5px;
}

#uncontrolled-tab-example-tab-Followers {
  margin-right: 438px;
}

@media (width >=649px) and (width <=767px) {
  #uncontrolled-tab-example-tab-Followers {
    margin-right: 179px;
  }
}

@media (width >=768px) and (width <=992px) {
  #uncontrolled-tab-example-tab-Followers {
    margin-right: 24px;
  }
}

@media (width >=992px) and (width <=1199px) {
  #uncontrolled-tab-example-tab-Followers {
    margin-right: 191px;
  }
}

@media (width >=1200px) and (width <=1399px) {
  #uncontrolled-tab-example-tab-Followers {
    margin-right: 313px;
  }
}