.menu_btn .btn {
    background-color: transparent !important;
    border: none !important;
}

.menu_icon_f {
    font-size: 30px;
}

.menuimg-width {
    width: 65% !important;
}

.custom_mrgin {
    margin-left: -9rem !important;
}

.dropdown-toggle::after {
    border-top: 0em solid !important;
    border-right: 0em solid transparent !important;
    border-left: 0em solid transparent !important;

}

.dropdown-menu {
    height: 150px;
    overflow-y: auto;
    inset: auto auto -6.9rem auto !important;
    transform: translate(130px, -75px) !important;
}

.font-lg {
    font-style: normal;
    font-size: 18px;
    font-weight: 500 !important;
}

.businessItemsScroll {
    height: 64vh;
    overflow-y: auto;
    overflow-x: hidden;
    cursor: pointer;
}

.businessItemsScroll::-webkit-scrollbar {
    display: none;
}

.profileImgbusiness img {
    width: 45%;
    border-radius: 50%;
    height: 120px;
}

.card-title {
    font-size: 14px;
}

@media (max-width: 1100px) and (min-width: 992px) {
    .font-lg {
        font-style: normal;
        font-size: 14px;

    }
}

@media (width >=768px) and (width <=991px) {
    .leftmenu_sett {
        font-size: 11px !important;
    }

}


@media (max-width: 991px) and (min-width: 320px) {

    .profileImgbusiness img {
        width: 20% !important;

    }
}

@media (width <768px) {

    .profileImgbusiness img {
        width: 22% !important;
        /* height: 125px; */
    }
}

@media (width <=447px) {

    .profileImgbusiness img {
        width: 35% !important;
        /* height: 109px; */
    }
}

@media (width >=990px) and (width <=1200px) {
    .setfonts {
        font-size: 14px !important;
    }
}


@media (width >=767px) and (width <=991px) {
    .setfonts {
        font-size: 14px !important;
    }
}