.Inner_form {
    background: #023535 !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 10px 3px #e7e8fb;
    border-radius: 8px;


}
.font_40{
    font-size: 40px!important;
}

.Input_lable {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #ffffff;
}

.Input_user {
    background: #FFFFFF;
    width: 100%;
    padding: 7px 8px;
    border-radius: 6px ;
    border: none !important;
}

.Input_Description {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    border-radius: 6px;
}



.img-frame {
    border: 4px solid white;
    border-radius: 9px;
}



.Avatar_text {
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    display: flex !important;
    align-items: center !important;
    color: #FFFFFF !important;
}

.Update_text {
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.Paragh-2 {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.img_width_upload {
    width: 25px !important;
}
.img_Avatar{
    width: 100%;
    height: 100px;
}

.create_btn_update{
    background-color: #0353a4;
    border: none !important;
    border-radius: 6px;
    width:93%; 
}