.suggest-img{
    width: 100%;
    border-radius: 50%;
}
.custm-screenshot{
    border:1px solid #3D3BEE;
    padding: 5%;

}
.click-p-style{
    font-size: 11px;
    color:#3D3BEE;
}
.butns-0{
    background-color:#dc3545 !important;
    color: white;
    border: transparent;
    font-size: 12px;
    border-radius: 6px;
    padding: 1px 15px 1px 15px ;
}
.butns-1 {
    background-color: #0353a4;
    color: white;
    border: transparent;
    font-size: 12px;
border-radius: 6px;
padding: 1px 15px 1px 15px ;

}