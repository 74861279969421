.img-Background {
    background-color: #94B6D2;
    border-radius: 74px;
    width: 140px;
    height: 140px;
}

.img-Background img {
    width: 100%;
    height: 100%;
    padding: 4px;
}

.leftsideback {
    background: #EAE8E8;
    border-radius: 10px;
    height: 100%;
}

.profile-1-scroll {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
}
.profile-bio-scroll-1{
    max-height: 78vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
}
.text-color-cv1 {
    color: #428CC4;
}

.lineColor {
    background: #428CC4;
    border-radius: 2px;
    border: transparent;
}
.progressColor{
background-color: red!important;
z-index: 1!important;
}