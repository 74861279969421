.report-bug {
    border: 1px solid #C4C4C4;
    border-radius: 6px;
    width: 30%;
}
.text-0 {
    border: 1px solid #C4C4C4;
    width: 97%;
    border-radius: 6px;
}
.report-hgt{
    height: 43vh;
    overflow: auto;
}
.report-image1 {
    width: 100%;
    height: 30vh;
}
.report-bttn button{
    padding: 0.25rem !important;
}