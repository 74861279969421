.delete-trash {
    padding: 3px 16px 3px 16px;
    background-color: #D62828;
    color: white;
    border: transparent;
    border-radius: 4px;
    font-size: 13px;
}

.restore-trash {
    padding: 3px 16px 3px 16px;
    background-color: #0353A4;
    color: white;
    border: transparent;
    border-radius: 4px;
    font-size: 13px;
    margin-left: 20px;
}

.juli-title{

    font-size: 15px;
    margin-bottom: 0px;
    padding: 3px 0px 3px 0px;
    font-weight: 500;

}

.class-for-scroll-juli{

    overflow-y: scroll;
    height: 84vh;

}



@media (max-width: 479px) and (min-width: 320px) {
    .restore-trash {
        height: 26px;
        width: 70px;
        font-size: 11px;
        margin-left: 15px;
    }

    .delete-trash{

        height: 26px;
        width: 70px;
        font-size: 11px;
    }

    .juli-title{

        font-size: 13px;
        
    }
}