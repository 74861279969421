.car_img_w{
    width: 100%;
    height: 400px;
    object-fit: cover;
}
.img-items{
    height: 400px !important;
}
.bg-app-btn{
    background: #EE3B3B;
}