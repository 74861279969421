.inputComment .custom-input{
    width: 94%;
    border: 1px solid #ccc;
    border-radius: 8px;
    color: blue;
    background-color: #F3F0F0;
    font-size: 13px;
    padding: 6px;
} 

.inputComment input{
    background: transparent;
    border: none;
    width: 85%;
}

.custom-input::placeholder {
    color: blue;
    
  }

  .commentImg {
    width: 56%;
    height: 56%;
    border-radius: 50%;
}

.Modal-head {
    height: 30vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.pera-one {
    background-color: #F3F0F0;
    border: transparent;
    border-radius: 8px;
    width: 94%;
   
}
.pera-one p{
    font-size: 11px;
    word-wrap: break-word;
    color: blue;
    width: 90%;
}
.ph-view{
    color: blue;
    font-size: 13px;
    cursor: pointer;
}


.ph-icon{
    color: blue;
    width: 17px !important;
    cursor: pointer;
}
@media (max-width: 530px) and (min-width: 450px) { 
    .commentImg {
        width: 80%;
        height: 45px;
        border-radius: 50%;
    }
}
@media (max-width: 449px) and (min-width: 380px) { 
    .commentImg {
        width: 42px;
        height: 46px;
        border-radius: 80%;
    }
    .Modal-head {
        height: 30vh;
        width: 360px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}
@media (max-width: 379px) and (min-width: 320px) { 
    .commentImg {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        
    }
    .Modal-head {
        height: 30vh;
        width: 316px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}