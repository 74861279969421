.btn-color{
    background: #3D3BEE;
    border-radius: 4px;
    border: transparent;
    color: white;
    font-size: 14px;
    padding: 9px 0px;
    width: 115px;
}

.btn-border-cv{
    background: transparent;
    border-radius: 4px;
    border:1px solid #3D3BEE;
    color: #3D3BEE;
    font-size: 14px;
    padding: 9px 0px;
    width: 140px;
}
.color-red{
    color: red;
    font-size: 17px!important;
}
.color-remove-red{
    color: red;
    font-size: 12px;
}
.color-text{
    color:#3D3BEE ;
    font-size: 12px;
}
.accordion-border{
    border: 1px solid  #3D3BEE;
}
.accordion-button {
    border-radius: 8px!important;
}

.accordion-item .accordion-button {
    padding: 12px 19px!important;
}
.accordion-header {
    border-radius: 8px!important;
}

.accordion-button:not(.collapsed) {
    color: white!important;
    background-color:#3D3BEE!important;
    box-shadow: none!important;
}
.accordion-button:focus {
    border-color: none!important;
    box-shadow: none!important;
}
.accordion-button:not(.collapsed)::after {
    background-image: url(../../assets/images/Form/arrowup.svg)!important;
    transform: rotate(0deg)!important;
    margin-top: 1px!important;
}
.accordion-body-open{
    height: 26vh!important;
    overflow-y: auto;
}

.color-one{
    color: blue;
}