.pooost #uncontrolled-tab-example-tab-ps {
  background-color: #0353a4;
  color: white;
  border-radius: 4px;
  padding: 5px 12px 7px 12px;
  margin-top: 4px;
}

#uncontrolled-tab-example-tab-pso { margin-left: 350px!important;
  background-color: #0353a4 !important;
  color: white;}
