.iput-search {
    border-color: #c6c6c6;
    border: 1px solid #B3B3B3 !important;
    box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.25) !important;
    border-radius: 6px;
    padding: 9px 10px;
    font-size: 12px;
    color: #7b7b93;
    width: 94.5% !important;
}
.input-ipt{
    width: 95%;
}
.btns-sty {
    border: transparent;
    background-color: #0353A4;
    color: white;
    font-size: 11px;
}
.messg-icon {
    position: absolute;
    right: 85%;
    color: white;
    top: 27px;
}