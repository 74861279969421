.heading-top {
  font-weight: 700 !important;
}
.heading-two {
  font-weight: 600 !important;
}
.labelone label {
  font-size: 16px !important;
  font-weight: 500 !important;
}
.mapset {
  position: absolute;
  width: 5%;
  bottom: 19%;
  left: 73%;
}
.maprel {
  position: relative;
}
.inpstyl:focus {
  border-color: #cccccc;
}
.promote-inputs-style{
  border: 1px solid #B3B3B3 !important;
  box-shadow: -2px 4px 6px 0px #00000040 !important;
  border-radius: 5px !important;
}
.des-placeholder textarea::placeholder{
  font-size: 12px !important; 
  padding-left: 5px;
}