.Main-card {
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FAFAFA;
    box-shadow: 0px 0px 7px 7px rgba(3, 83, 164, 0.30) inset;
    padding-bottom: 4%;
    
}

.mail_icon {
    font-size: 1.2rem!important;
}

.card-second-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


}


.dashboard_card {
    width: 70px!important;
    height: 70px !important;

}

.dashboard_card_r {
   border-radius: 50px;

}



.width-box {

    width: 100% !important;
    border-radius: 20px;
    
}

.btn-clr {

    background: #FFFFFF;
    border: 1px solid #111111;
    border-radius: 20px;
    font-size: 14px;
    padding: 3px 5px;
    width: 100px;
    color:black;
    font-weight: 600;
    

}

.btn-clr-plus{
    background: #FFFFFF;
    border: 1px solid #111111;
    border-radius: 20px;
    font-size: 14px;
    padding: 1px 7px;
    width: 130px;
    color:black;
    font-weight: 600;
}

.btn-clr:hover , .btn-clr-plus:hover{
    background-color: #020094;
    color:white;
}

.addjust{
    flex-direction: row;
    align-items: flex-end;
}

.title-size {

    font-size: 13px;
    font-weight: 700;

}

.mail_iconone{
    
    font-size: 0.8rem;
    width: 12px;
    height: 13px;
}



@media (max-width: 991px) and (min-width: 768px) { 

    .title-size {

        font-size: 12px!important;
        font-weight: 700!important;
    
    }

   
}


@media (max-width: 767px) and (min-width: 685px) { 
    .title-size{
        font-size: 11px!important;
        font-weight: 700!important;
    }
} 

@media (max-width: 1199px) and (min-width: 1024px) { 
    .title-size{
        font-size: 11px!important;
        font-weight: 900!important;
    }

} 






@media (max-width: 991px) and (min-width: 768px) {

    .dashboard_card {
        width: 40px!important;
        height: 40px !important;
    
    }

 } 


 @media (max-width: 1199px) and (min-width: 1024px) { 

    .dashboard_card {
        width: 40px!important;
        height: 40px !important;

        
    }
    .mail_icon {
        font-size: 1rem!important;
    }
   
 }







  @media (max-width: 1023px) and (min-width: 992px) { 
    .mail_icon  {
        font-size: 1rem!important;
    } 

    .dashboard_card {
        width: 50px!important;
        height: 50px !important;

        
    }

    .title-size{
        font-size: 11px!important;
        font-weight: 900!important;
    }
  }

