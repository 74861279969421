/* .dropdown {
     position: absolute !important;
    top: 16px !important;
    right: 0px !important;
    

    background-color: white;
    border: 1px solid rgba(140, 138, 138, 0.5);
    box-shadow: 0px 3px 10px 4px rgba(140, 138, 138, 0.25);
} */
.dropdownone {
    position: absolute;
    top: 30%;
    right: 2.5%;
    background-color: white;
}