.business-images {
    height: 56px;
    margin-top: -13px
}

.business-images-1 {
    height: 56px;
    margin-top: -13px
}

.business-images img {
    width: 100%;
    height: 100%;
}

.business-images-1 img {
    width: 100%;
    height: 90%;
}

.color-black {
    color: black !important;
}

.badge-icon {
    font-size: 0.50rem !important;
    min-width: 11px !important;
    padding: 0 6px !important;
    height: 16px !important;
    border-radius: 50px !important;
    top: 0;
    right: 0;

}

.businesscardsStyle {
    background: #FFFFFF !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3) !important;
    border-radius: 8px !important;
}

.serviceColmn {
    width: 10.25rem ;
    background: #FFFFFF !important;
    border: 1px solid #C4C4C4 !important;
    border-radius: 8px;
    margin-right: 0px;
    height: 142px ;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.businesscardscenter {
    display: flex;
    justify-content: space-evenly;
}