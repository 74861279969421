.silver-icon {
    color: #7B7B93 !important;
}

.plus-icon {
    font-size: 20px !important;
    background-color: #3D3BEE;
    padding: 2px 2px;
    border-radius: 6px;
}

.add-icon-size {
    width: 40px !important;
    height: 40px !important;
}
.place::placeholder{
    color: black;
}
