.Main-nav-css {

    background-color: white;
    padding: 4px;
    border-radius: 8px;
    height: 75%;
    border-radius: 20px;
}

.Alldashboardfont {
    border-radius: 20px;
    font-size: 13px;
}

@media (width <=1000px) {

    .Alldashboardfont {

        font-size: 10px !important;
    }
}

@media (width <=1120px) {

    .Alldashboardfont {

        font-size: 11px;
    }

    .btn-clr-plus {
        font-size: 10px;
        width: 102px;
    }
}

@media (max-width: 479px) and (min-width: 373px) {
    .Main-nav-css {
        margin-top: 10px !important;
    }

    .Main-nav-css {

        background-color: white;
        padding: 4px;
        border-radius: 8px;
        height: 75%;

    }

    .dash-btn {
        font-size: 9px !important;
        padding: 6px 1px !important;
        margin-bottom: 12px !important;
        border-radius: 15px !important;
    }
}

@media (max-width: 400px) and (min-width: 373px) {
    .title-size {
        font-size: 9px !important;
        font-weight: 700 !important;
    }
}

@media (max-width: 565px) and (min-width: 401px) {
    .title-size {
        font-size: 11px !important;
        font-weight: 700 !important;
    }
}

@media (max-width: 372px) and (min-width: 320px) {
    .Main-nav-css {
        margin-top: 10px;
    }

    .Main-nav-css {

        background-color: white;
        padding: 4px;
        border-radius: 8px;
        height: 75%;

    }

    .dash-btn {
        font-size: 8px !important;
        padding: 6px 1px !important;
    }

    .title-size {
        font-size: 8px !important;
        font-weight: 700 !important;
    }
}

@media (max-width: 567px) and (min-width: 480px) {

    .Main-nav-css {
        margin-top: 10px;
    }
}