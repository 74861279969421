[data-action="close"] {
    display: none !important;
}

.business_avataar {
    height: 50%;
}

.pedin-one {
    padding-bottom: 100px;
}

.tourSize {
    font-size: 14px !important;
}

@media (max-width: 1500px) and (min-width: 1300px) {
    .dash-btn {
        font-size: 13px;
    }

    .title-size {
        font-size: 12px !important;
        font-weight: 700 !important;
    }
}

@media (max-width: 1299px) and (min-width: 1200px) {
    .dash-btn {
        font-size: 11px;
    }

    .title-size {
        font-size: 11px !important;
        font-weight: 400 !important;
    }
}

@media (max-width: 1199px) and (min-width: 1100px) {
    .dash-btn {
        font-size: 12px;
    }

    .title-size {
        font-size: 11px !important;
        font-weight: 700 !important;
    }

    .cards-shadow {
        height: 235px !important;
    }

    .btn-clr {
        font-size: 12px !important;
        padding: 4px !important;
        width: 83px !important;
    }

    .shadow-main {
        padding: 18px 8px !important;
    }

    .shadow-joboprt {
        padding: 18px 7px !important;
    }
}

@media (max-width: 1099px) and (min-width: 1024px) {
    .dash-btn {
        font-size: 13px;
        padding: 4px 9px;
    }

    .title-size {
        font-size: 10px !important;
        font-weight: 700 !important;
    }

    .cards-shadow {
        height: 235px !important;
    }

    .btn-clr {
        font-size: 12px !important;
        padding: 4px !important;
        width: 83px !important;
    }

    .shadow-main {
        padding: 18px 2px !important;
    }

    .shadow-joboprt {
        padding: 18px 2px !important;
    }

    .Rosie {
        font-size: 17px !important;
    }

    .font {
        font-size: 13px !important;
    }
}

@media (max-width: 1023px) and (min-width: 992px) {
    .dash-btn {
        font-size: 13px;
        padding: 4px 10px;
    }

    .title-size {
        font-size: 9px !important;
        font-weight: 700 !important;
    }

    .cards-shadow {
        height: 243px !important;
    }

    .btn-clr {
        font-size: 12px !important;
        padding: 4px !important;
        width: 83px !important;
    }

    .shadow-main {
        padding: 18px 2px !important;
    }

    .shadow-joboprt {
        padding: 18px 2px !important;
    }

    .Rosie {
        font-size: 17px !important;
    }

    .font {
        font-size: 13px !important;
    }
}

@media (max-width: 991px) and (min-width: 768px) {

    .container-md,
    .container-sm {
        max-width: 893px !important;
    }

    .shadow-main {
        padding: 18px 4px !important;
    }

    .shadow-joboprt {
        padding: 18px 4px !important;
    }
}

@media (max-width: 767px) and (min-width: 576px) {

    .container,
    .container-sm {
        max-width: 651px !important;
    }
}

@media (max-width: 575px) and (min-width: 320px) {
    .Butoonn2 {
        font-size: 14px !important;
        padding: 3px !important;
        width: 50% !important;
    }

    .Butoonn {
        width: 50% !important;
    }

    .shadow-main {
        padding: 4px 4px;
    }

    .Main-card {
        margin: 0px !important;
    }

    .card-img {

        height: 80px !important;

    }

    .selectdiv select {
        width: 290px;
    }

    .shadow-joboprt {
        background: #fafafa;
        border: 1px solid #a8a8a8;
        box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 4px 4px;
    }
}