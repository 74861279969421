
.tablebody{
    height: 20px;
}

.tables{
    width: 100%;
}
.table-responsive .tables{
/* width: 25%!important; */
/* height: 215px!important; */
}
.headtxts{
    font-size: 13px;
    width: 25%!important;
}
