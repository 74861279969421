.shops_buying_h::-webkit-scrollbar {
  display: none;
}

.right-to-left-modal .modal-dialog {
  transform: translateX(120%) !important;
  animation: slide-in .6s forwards !important;
  width: 24.5rem;
}

@keyframes slide-in {
  100% {
    transform: translateX(0) !important;
  }
}


.modal-height {

  height: 222px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 4px;
  background-color: #EDEFF4;


}




.bg-clr-card{

  background-color: white;

}

.back-icon {

  cursor: pointer;

}

.title-sizze {

  font-size: 12px;
  font-weight: 500;

}

.btnn {

  border: transparent;
  background-color: #EDEFF4;

}

.option-style {

  width: 32%;

}

.delete-btn-three {

  color: #EE3B3B;
  padding: 1px 4px;
  font-size: 11px;
  border: 1px solid;
  border-radius: 11px;
  cursor: pointer;

}

.Qty {

  /* background-color: #EDEFF4; */
  width: 20%;
  border-radius: 3px;
  padding-left: 6px;
  font-size: 12px;
  border: 1px solid rgb(3, 118, 164);

}

.price {

  color: rgb(34, 32, 41);
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 700;

}

.change-btn{

  border: transparent;
  color: #3D3BEE;
  background-color: transparent;

}

.p-tag{
  margin-top: 100px;
  margin-bottom: 8px;
  font-weight: 600;

}

.bag-t{

  color: #777777;

}

.bag-t-2{

margin-top: 8px;

}

.t-amount{

  font-weight: 500;

}
.t-amount-2{
 
  font-weight: 500;

}

.fs-imgs{

  font-size: 10px;

}

.place-order{

  background-color: #003559;
  border: transparent;
  color: #FFFF;
  height: 30px;
  width: 100%;
  

}

@media (max-width: 1023px) and (min-width: 992px) {

  .right-to-left-modal .modal-dialog {
    transform: translateX(65%) !important;
    animation: slide-in .6s forwards !important;
    width: 24.5rem;
  }

  .right-to-left-modal{

    width: 100%;

  }
 }
@media (max-width: 991px) and (min-width: 768px) {

  .right-to-left-modal .modal-dialog {
    transform: translateX(50%) !important;
    animation: slide-in .6s forwards !important;
    width: 24.5rem;
  }

  .right-to-left-modal{

    width: 100%;

  }

 } 
@media (max-width: 767px) and (min-width: 685px) {

  .right-to-left-modal .modal-dialog {
    transform: translateX(40%) !important;
    animation: slide-in .6s forwards !important;
    width: 24.5rem;
  }


 } 
@media (max-width: 684px) and (min-width: 568px) {

  .right-to-left-modal .modal-dialog {
    transform: translateX(25%) !important;
    animation: slide-in .6s forwards !important;
    width: 24.5rem;
  }

 } 
@media (max-width: 567px) and (min-width: 480px) { 

  .right-to-left-modal .modal-dialog {
    transform: translateX(20%) !important;
    animation: slide-in .6s forwards !important;
    width: 22rem;
  }

  .right-to-left-modal{

    width: 100%;

  }

}
@media (max-width: 479px) and (min-width: 320px) { 

  .right-to-left-modal .modal-dialog {
    transform: translateX(25%) !important;
    animation: slide-in .6s forwards !important;
    width: 20rem;
  }

  .right-to-left-modal{

    width: 100%;

  }

}