.Restaurant-img {
  width: 100%;
  border-radius: 50%;
  height: 254px;
}
.picset {
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.custom-button {
  background-color: rgba(233, 167, 29, 1) !important;
  color: white;
}

.custom-button-one {
  white-space: nowrap;
  font-size: 11px;
  border-radius: none !important;
}

.custom-icon {
  font-size: 5px;
  vertical-align: start;
}

.custom-button-two {
  white-space: nowrap;
}

.customs-buttons-one {
  white-space: nowrap;
}
