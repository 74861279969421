.font-size-items {
    font-size: 25px!important;
    text-align: center!important;
}

.pd-box-1 {
    padding: 35px 40px !important ;
}

.pd-box-2 {
    padding: 35px 60px!important;
}

.height-boxes {
    height: 80vh!important;
}