.cv-header-5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #EAC31D;
}
.title-margin-5{
    margin-bottom: 0px;
    color: #F5F5F5;
    font-weight: 500;
    font-size: 14px;
}
