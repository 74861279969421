
.before{
    background: #006DAA;
}
.inactive{
    background: white;
}

.modal_height{
    height:80vh;
}
.col_custm{
    background-color: #212121;
    padding: 6rem;
     border-radius: 6px;
}
.btn-continue{
 background: #006DAA;
 color: white;
 border-radius: 6px;
 border: none !important;
    
}
.back-arrow-font{
    font-size: 2rem !important;
}