.header{
    height: 660px!important;
    overflow-y: auto;
}
.PHARA{
    font-size: 11px;
    color: rgba(33, 33, 33, 0.4);

}

.time{
 
    font-size: 10px;
}

.pick{

    width: 100% !important;
}
.butns{
    display: flex;
    margin-bottom: 4px;
    justify-content: space-between;
}
.butns1 {
    background-color: #53D750 !important;
    width: 55%;
    margin-left: -3vh;
    height: 22px;
    border-radius: 5px;
    border: transparent;
    font-size: 11px;
    color: white;
}

.butns2 {
    border: transparent;
    height: 21px !important;
    padding: 0px !important;
    font-size: 11px !important;
    width: 55%;
    background-color: #EE3B3B;
    border-radius: 5px;
    color: white;
}
.butns3 {
    width: 61%;
    height: 24px;
    font-size: 12px;
    color: white;
    background-color:  #EE3B3B;
    border: transparent;
    border-radius: 6px;
}

.qe {
    margin: 0px;
    margin-top: 5px;
}

