.icon_setting {
    font-size: 26px !important;
    margin-top: 4px;
}

.logo {
    font-weight: 900;
    color: #0353A4;
    font-size: 1.4rem;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer
}

.nav-absolute {

    background: white !important;
    position: fixed;
    z-index: 2;
}

.img-width {
    width: 30px;
}

.img-width-1 {
    width: 25px;
}

.text-style {
    color: #0353A4 !important;
}

.menu_active .active {
    color: #3d3bee !important;
    border-bottom: 3px solid #3d3bee !important;
    padding-bottom: 10px !important;
}

.navbar_f {
    font-size: 1rem !important;
    font-weight: 500 !important;

}

.container-border-b {
    border-bottom: 1px solid #c4c4c4;
}



.setlinks_onsm {
    display: block;
}

@media (width <=448px) {
    .setlinks_onsm {
        display: none !important;
    }
}

@media (max-width: 575px) and (min-width: 338px) {
    .logo {
        font-size: 1rem !important;
    }

    .smallFont {
        font-size: 13px !important;
    }

    .smallFonthome {
        font-size: 15px !important;
    }

    .img-width {
        width: 23px !important;
    }

    .left-arrow-w {
        width: 67px !important;
    }
}

@media (max-width: 377px) and (min-width: 327px) {
    .logo {
        font-size: 0.7rem !important;
    }

    .smallFont {
        font-size: 11px !important;
    }

    .smallFonthome {
        font-size: 14px !important;
    }

    .img-width {
        width: 20px !important;
    }

    .switch_text {
        font-size: small !important;
    }

    .left-arrow-w {
        width: 67px !important;
    }

}

@media (width <=326px) {
    .logo {
        font-size: 12px !important;
    }

    .smallFont {
        font-size: 9px !important;
    }

    .smallFonthome {
        font-size: 11px !important;
    }

    .img-width {
        width: 18px !important;
    }

    .switch_text {
        font-size: smaller !important;
    }

    .icon_setting {
        font-size: 21px !important;
    }

    .left-arrow-w {
        width: 67px !important;
    }


}

/* @media (max-width: 337px) and (min-width: 320px) {
    .logo {
        font-size: 0.7rem !important;
    }

    .smallFont {
        font-size: 11px !important;
    }

    .smallFonthome {
        font-size: 14px !important;
    }

    .img-width {
        width: 20px !important;
    }
} */






@media (max-width: 870px) {
    .massengerShowComputer{
        display: none;
    }
}



@media (min-width: 870px) {
    .massengerShowMobile{
        /* border: 1px solid transparent; */
        display: none;
    }
}