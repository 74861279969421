.restaurant-container {
    /* transition: all 0.3s ease-in-out; */
    transition: transform 0.3s ease-out;

    width: 137%; 
   
  }
  
  .restaurant-container.shrink {
    transform: scale(0.6); 
    margin-top: 100px;
     margin-left: -300px;
     
  }

  /* .shrink-navbar {
    height: 50px; 
    background-color: #333; 
    
  } */


  .shrink-one{
    margin-top: -80px!important;
    transform: scale(0.9);
    margin-left: -60px;
  }

  .images-shopdata{
    width: 100%;
  }