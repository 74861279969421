
.group_scroll{
    height: 670px;
    overflow-y: scroll;
}
.ownchatroomcontainer {
  
   
    background: #4B123E!important;
    border-radius: 8px;
}
    .activechatroom {
        position: absolute;
        right: 0;

      

    }

    .hide-desktop-view{
        display:block;
    }
    .hide_dropdown{
        display: none;
    }

    .dotttt {
        border-radius: 50%;
        background: #0FA958;
        color: #0FA958;
        padding: 1px 6px 1px 6px;
        font-size: 10px;
        margin-right: 0.3rem;
    }
    .ownchat-btn {
        background: white !important;
        border-radius: 6px;
        padding: 5px 20px 5px 20px;
        color: black !important;
        font-size: 13px;
        border:none;
    }

    .createchatroom-btn {
        background: white !important;
        border-radius: 6px;
        padding: 5px;
        color: black !important;
        font-size: 13px;
        border:none;
    }

    .ownchatroombody {
        background: #267DCF !important;

        

    }

    .ownchatroombody     h6 {
        font-weight: 400;
        font-size: 13px;
    }

    .own-btn {
        background: #FFFFFF;
        border-radius: 10px;
        color: #267DCF;
        font-size: 11px;
        padding: 1px 20px 1px 20px;
        border: transparent;
    }



.scrollingLiveChats{
    background-color: #023535;
    height: 81vh;
    overflow: auto;
}

@media only screen  and (max-width: 1399px) {
    .createchatroom-btn{
        padding: 3px;
    }
    .ownchat-btn{
padding: 3px 9px;
    }
}

@media only screen and (max-width:1199px){
    .createchatroom-btn {
        font-size: 10px;
    }

    
    .ownchat-btn{
        font-size: 10px;
}
}
@media only screen and (max-width:767px){
    .hide-desktop-view{
        display: none !important;
    }
    .hide_dropdown{
        display: block;
    }
}

@media only screen and (max-width:575px){
    .ownchatroomcontainer{
        margin-top: 1rem;
    }
}