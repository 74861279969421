.main-dropdown {
  position: relative;
  display: inline-block;
}

.main-contentdata {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

/* .dropdown-contents {
    display: block;
  } */

.Button-styleset {
  display: block;
  width: 130px;
  padding: 2px;
  text-align: center;
  /* background-color: yellow; */
  color: #fff;
  /* text-decoration: none !important; */
}
.main-contentdata a {
  text-decoration: none !important;
  cursor: pointer;
}
.main-contentdata a:hover {
  text-decoration: none !important;

  color: #fff;
}
.mainButtonStyl {
  /* background-color: orange; */
  color: #fff;
  text-decoration: none;
  width: 130px;
  padding: 2px;
  border: none;
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  padding-left: 26px;
}
