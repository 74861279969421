.img-2-Background  {
    background-color: white;
    border-radius: 74px;
    width: 140px;
    height: 140px;
}

.img-2-Background  img {
    width: 100%;
    height: 100%;
    padding: 4px;
}

.template-2-back{
    background: #31521B;
    border-radius: 10px;
    height: 100%;
}


.profile-2-scroll {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
}

.profile-bio-scroll-2{
    max-height: 88vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 2px;
}
.CV-green-back{
    background-image: url(../../../../../assets/images/cv/cvgreen.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

