.aln-ftr {
  width: 160px;
  height: 50px;
  left: 200px;
  top: 940px;

  background: #302d2d;
  border-radius: 8px;
  color: white;
}

.and-olm {
  width: 50px;
  height: 50px;
  /* left: 670px;
  top: 940px; */

  background: #302d2d;
  border-radius: 8px;
  color: white;
}

@media (max-width: 1023px) and (min-width: 992px) { 
  .lmcf-one{
    margin-top: 72px;
  }

}

@media (max-width: 1199) and (min-width: 1024) {
  .lmcf-one{
    margin-top: 29px;
  }
}


