.hour_height {
  height: 35vh !important;
  overflow: hidden;
}
.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.atd-oo {
  cursor: pointer;
}

.bunn-btc {
  border: transparent;
  background-color: transparent;
  color: black;
}

.hors-ont {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;

  color: #9999ac;
}

.and-twn {
  border: 1px solid rgba(196, 196, 196, 0.9);
  border-radius: 4px;
  width: 86px;
  height: 30px;
  left: 690px;
  top: 448px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  /* line-height: 19px; */

  color: #9999ac;
}

.asd-atm {
  width: 183px;
}

.Hours-set {
  width: 30px;
  height: 30px;

  background: #ffffff;
  box-shadow: 1px 2px 8px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.bmd-onn {
  border: transparent;
  background-color: transparent;
  width: 20px;
  height: 40px;
}

.mtc-nn {
  width: 20px;
  height: 40px;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.modal-one {
  width: 302px;
  height: 323px;
}

.oon-et {
  width: 84px;
  height: 37px;
  font-weight: 700;
}

.tablee-one {
  width: 250px;
  height: 170px;
  display: flex;
  justify-content: center;
  /* left: 862px;
top: 521px; */

  background: #ffffff;
  box-shadow: 1px 2px 8px 1px rgba(0, 0, 0, 0.25);
}

.bclass {
  background-color: white;
}

/* .time-selector {
  position: relative;
  z-index: 1;
  top: 50%;
  left: 40%;
  background-color: white;
 
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  
}

.time-selector select {
  margin: 0 5px;
}

#hour, #minute {
  margin-top: 8px;
}


.time-selector p {
  margin: 0;
} */

/* .box {
  width: 200px;
  height: 150px;
  border: 1px solid #ccc;
  overflow: auto;
}

.scroll {
  list-style: none;
  margin: 0;
  padding: 0;
}

.scroll li {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.scroll span {
  display: inline-block;
  width: 25px;
} */

/* .container {
.container-bem {
  display: flex;
  height: 150px;
} */

.box-eem {
  flex: 1;
  border: 1px solid #ccc;
  overflow-y: auto;
  height: 33vh;
  margin: 4px;
}

.scroll {
  list-style: none;
  margin: 0;
  padding: 0;
}

.scroll li {
  padding: 5px;
  /* border-bottom: 1px solid #ccc; */
  background: transparent;
}

.mdol-seet {
  height: 79vh;
  width: 499px;
  padding: 9px;
}

.modal-showw {
  width: 580px;
}



.schedule-icon {
  color: grey;
  font-size: 20px !important;

}

.schedule-icon-active {
  color: rgb(66, 76, 216);
  font-size: 1.5rem !important;

}


