.modalcolor {
  background-color: #0353a4;
}
.fw-bolder{
  border-top-right-radius: 0px !important;
}
.changes-clr {
  color: #0353a4;
}

.custom-butn-set {
  right: -2px;
  top: 1px;
}
