
.last-buttons{

    display: flex;
    justify-content: flex-end;
    padding-bottom: 14px;

}

.message-chatting-btn{

    padding: 8px 30px;
    background-color: #212121;
    color: white;
    border: transparent;
    border-radius: 6px;
    margin-right: 16px;

}

.primary-chatting-button{

    padding: 8px 40px;
    background-color: #0353A4;
    color: white;
    border: transparent;
    border-radius: 6px; 


}

.input-field-class{
    background-color: white;
    width: 100%;

}