.MALS {
    color: #267DCF !important;
    
}

.txt {
    padding: 1px 7px;
    height: 10px;
    cursor: pointer;
}

.dropdownss {
     position: absolute !important; 
     right: 38px;
     top: 2px;

    background-color: white;
    border: 1px solid rgba(140, 138, 138, 0.5);
    box-shadow: 0px 3px 10px 4px rgba(140, 138, 138, 0.25);
}

.modl {
    background-color: #212121;
    color: white;
    border-radius: 7px;

}

.btnclose {
    background-color: #D62828 !important;
    color: white !important;
    width: 30%;
    height: 28px;
    border-radius: 6px;

}



