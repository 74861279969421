.btn-application-grey{
    background-color: #E9A71D;
    border: transparent;
    color: white;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
}
.btns-connects {
    background-color: #0353A4!important;
    color: white !important;
    width: 100% !important;
    border: transparent !important;
    border-radius: 7px;
}

.custom-available-button{
    background-color: white !important;
    border: 1px solid green !important;
    white-space: nowrap;
    color: green !important;
    cursor:auto !important;
}