.livechat-btn{
    background: #212121 !important;
    border-radius: 6px;
    padding: 2px 10px 2px 10px;
    font-size: 13px;
}
.live_chat_height{
    overflow-y: auto;
    overflow-x: hidden;
    height: 82vh;
}