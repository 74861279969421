.commentsBackground {
    border-radius: 8px;
    height: auto;
    overflow: hidden;
    background:#267DCF;
}

.scrollinginvitePeople {
    overflow: auto;
    height: 495px;
}


.searchInput button {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 35px;
    width: 50px;
    background: blue;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border: none;
   

}

.commentsImg img {
    height: 50px;
}



.liveChatDotts {
    color: white;
}

.dottsBoxAbsolute {
    position: absolute;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 5px 10px;
    right: 18px;
    margin-top: 20px;
}
.crossStyle{
    text-align: flex-end;
}
.removeBlock {
    font-size: 13px;
}
