.segment-container {
    display: flex;
    border-radius: 20px; /* Rounded container */
    overflow: hidden; /* Ensure rounded corners are visible */
    background-color: #e9edf3;
    /* Optional: Add other styling properties like padding, border, etc. */
  }
  
  .segment-button {
    flex: 1;
    /* padding: 2px 0; */
    
    text-align: center;
    border: none;
    background-color: transparent;
    border-radius: 20px; /* Rounded buttons */
    cursor: pointer;
    transition: background-color 0.5s ease-in-out;
    font-size: 12px;
    font-weight: bold;
  }
  
  .segment-button.active {
    background-color: green;
    transition: background-color 0.5s ease-in-out;
    color: white; /* Optional: Change text color for better visibility */
  }