.alt-seet {
  width: 100% !important;
  border-radius: 8px;
}

.postn-abtn {
  width: 97px;
    height: 28px;
    top: 8px;
    right: 24px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}
.seeter-tlt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: white;
}

.nat-roa{
  width: 99%;
  
}


/* @media (max-width: 1400px) and (min-width: 1200px) {
    .postn-abtn  {
        position: sticky;
        width: 100px;
        height: 30px;
        top: -491px;
        right: 0px;
        left: 827px;
    }
} */


/* @media (max-width: 1023px) and (min-width: 992px) { 

  .postn-abtn {
    width: 93px;
    height: 30px;
    top: 7px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}
  

  .alt-seet {
    width: 100% !important;
    border-radius: 8px;
  }

  .nat-roa{
    width: 100%;
    height: 400px;
    
  }


} */

@media (max-width: 1400px) and (min-width: 1200px) { 
  .nat-roa{
    width: 99%;
    height: 400px;
    
  }
  .postn-abtn {
    width: 84px;
    height: 30px;
    top: 8px;
    right: 22px;
}
} 

@media (max-width: 1199px) and (min-width: 1024px) { 
  .alt-seet {
    width: 99% !important;
    border-radius: 8px;
  }

  .nat-roa{
    width: 100%;
    height: 400px;
    
  }

}
@media (max-width: 1023) and (min-width: 992) {
  .alt-seet {
    width: 100% !important;
    border-radius: 8px;
  }

  .nat-roa{
    width: 101%;
    height: 400px;
    
  }

}

@media (max-width: 1199) and (min-width: 1024) {
  .postn-abtn {
    right: 11px;
}
}