body {
    overflow-x: hidden;
}



.profileImg {
    height: 36%;
}

.profileImg img {
    width: 36%;
}

.dash-location {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #7B7B93;

}

.background-dashboard-color {
    height: 93vh;
    margin-top: 53px !important;
}

.view-button {
    background: #003559;
    border-radius: 6px;
    color: white;
    border: transparent;
    padding: 5px 12px;
    font-size: 12px;
}

.welcomeSpacing {
    margin: -2.5px 9px 10.5px 13px;
    background: #0353A4;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 30%);
    border-radius: 8px;
}

.welcome-text {
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    color: white;
    padding: 3px 0px;
}

.marginsettings {
    margin-left: 1rem;
}

@media (max-width: 1700px) and (min-width: 1600px) {
    .font-up-cards {
        font-size: 12px !important;
    }

    .font-down-cards {
        font-size: 16px !important;
    }

    .Name {
        margin: 0px !important;

    }

    .businesscards {
        height: 142px !important;
        width: 10.1rem !important;
    }

    .background-dashboard-color {
        display: flex;
        align-items: center;
    }

    .serviceColmn {
        width: 11.8rem !important;
    }
}

@media (max-width: 1599px) and (min-width: 1501px) {
    .font-up-cards {
        font-size: 12px !important;
    }

    .font-down-cards {
        font-size: 16px !important;
    }

    .Name {
        margin: 0px !important;

    }

    .businesscards {
        height: 142px !important;
        width: 9.5rem !important;
    }

    .background-dashboard-color {
        display: flex;
        align-items: center;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 11rem !important;
        margin-right: 8px !important;
    }
}

@media (max-width: 1500px) and (min-width: 1441px) {
    .font-up-cards {
        font-size: 12px !important;
    }

    .font-down-cards {
        font-size: 16px !important;
    }

    .Name {
        margin: 0px !important;

    }

    .businesscards {
        height: 142px !important;
        width: 9.5rem !important;
    }

    .background-dashboard-color {
        display: flex;
        align-items: center;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 10.4rem !important;
        margin-right: 8px !important;
    }
}

@media (max-width: 1440px) and (min-width: 1380px) {

    .background-dashboard-color {
        display: flex;
        align-items: center;
        height: 93vh !important;
        margin-top: 53px !important;
    }

}

@media (max-width: 1379px) and (min-width: 1300px) {
    .font-up-cards {
        font-size: 11px !important;
    }

    .font-down-cards {
        font-size: 15px !important;
    }

    .Name {
        margin: 0px !important;

    }

    .businesscards {
        height: 132px !important;
        width: 8.3rem !important;
    }

    .background-dashboard-color {
        display: flex;
        align-items: center;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 9.4rem !important;
        margin-right: 8px !important;
        height: 132px !important;
    }
    .CardSize {
        height: 277.4px!important;
    }
    .view-button {
        padding: 5px 6px !important;
    }

}

@media (max-width: 1299px) and (min-width: 1200px) {
    .font-up-cards {
        font-size: 9px !important;
        font-weight: normal !important;
    }

    .font-down-cards {
        font-size: 12px !important;
    }

    .Name {
        margin: 0px !important;

    }

    .businesscards {
        height: 131px !important;
        width: 8.1rem !important;
    }

    .background-dashboard-color {
        display: flex;
        align-items: center;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 8.7rem !important;
        margin-right: 8px !important;
        height: 131px !important;
    }

    .view-button {
        padding: 7px 4px !important;
        font-size: 11px !important;
    }

}

@media (max-width: 1199px) and (min-width: 1100px) {
    .font-up-cards {
        font-size: 9px !important;
        font-weight: normal !important;
    }

    .font-down-cards {
        font-size: 12px !important;
    }

    .Name {

        font-size: 22px !important;
    }

    .dash-location {
        font-size: 13px !important;
    }

    .businesscards {
        height: 131px !important;
        width: 7.4rem !important;
    }
    .CardSize {
        height: 276.4px!important;
    }
    .background-dashboard-color {
        display: flex;
        align-items: center;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 7.9rem !important;
        margin-right: 8px !important;
        height: 131px !important;
    }

    .view-button {
        padding: 7px 5px !important;
        font-size: 9px !important;
    }

    .btn-dash-sm {
        width: 101px !important;
    }
}

@media (max-width: 1099px) and (min-width: 1050px) {
    .font-up-cards {
        font-size: 8px !important;
        font-weight: normal !important;
    }

    .font-down-cards {
        font-size: 12px !important;
    }

    .Name {

        font-size: 22px !important;
    }

    .dash-location {
        font-size: 10px !important;
    }

    .businesscards {
        height: 131px !important;
        width: 6.8rem !important;
    }

    .background-dashboard-color {
        display: flex;
        align-items: center;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 7.5rem !important;
        margin-right: 8px !important;
        height: 131px !important;
    }

    .view-button {
        padding: 7px 5px !important;
        font-size: 8px !important;
    }

    .btn-dash-sm {
        width: 92px !important;
        font-size: 13px !important;
    }
}

@media (max-width: 1049px) and (min-width: 1000px) {
    .font-up-cards {
        font-size: 8px !important;
        font-weight: normal !important;
    }

    .font-down-cards {
        font-size: 11px !important;
    }

    .Name {

        font-size: 22px !important;
    }

    .dash-location {
        font-size: 10px !important;
    }

    .businesscards {
        height: 130px !important;
        width: 6.4rem !important;
    }

    .background-dashboard-color {
        display: flex;
        align-items: center;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 7.1rem !important;
        margin-right: 8px !important;
        height: 130px !important;
    }

    .view-button {
        padding: 6px 4px !important;
        font-size: 7px !important;
    }

    .btn-dash-sm {
        width: 92px !important;
        font-size: 13px !important;
    }

    .business-images {
        height: 45px !important;
        margin-top: -2px !important;
    }

    .business-images-1 {
        height: 45px !important;
        margin-top: -2px !important;
    }

    .CardSize {
        height: 273.4px !important;
    }
}

@media (max-width: 999px) and (min-width: 950px) {
    .font-up-cards {
        font-size: 8px !important;
    }

    .font-down-cards {
        font-size: 11px !important;
    }

    .Name {

        font-size: 22px !important;
    }

    .dash-location {
        font-size: 10px !important;
    }

    .businesscards {
        height: 130px !important;
        width: 6.4rem !important;
    }

    .background-dashboard-color {
        display: flex;
        align-items: center;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 6.8rem !important;
        margin-right: 8px !important;
        height: 130px !important;
    }

    .CardSize {
        height: 273.4px !important;
    }

    .business-images {
        height: 45px !important;
        margin-top: -2px !important;
    }

    .business-images-1 {
        height: 45px !important;
        margin-top: -2px !important;
    }

    .view-button {
        padding: 6px 4px !important;
        font-size: 6px !important;
    }

    .btn-dash-sm {
        width: 92px !important;
        font-size: 13px !important;
    }

    .marginsettings {
        margin-left: 4px !important;
    }
}

@media (max-width: 949px) and (min-width: 900px) {
    .font-up-cards {
        font-size: 7px !important;
    }

    .font-down-cards {
        font-size: 9px !important;
    }


    .Name {
        font-size: 18px !important;
    }

    .dash-location {
        font-size: 10px !important;
    }

    .business-images-1 {
        height: 45px !important;
        margin-top: 0px !important;
    }

    .businesscards {
        height: 129px !important;
        width: 5.7rem !important;
    }

    .CardSize {
        height: 274.7px !important;
    }

    .background-dashboard-color {
        display: flex;
        align-items: center;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 6.1rem !important;
        margin-right: 8px !important;
        height: 129px !important;
    }

    .view-button {
        padding: 6px 4px !important;
        font-size: 6px !important;
    }

    .btn-dash-sm {
        width: 73px !important;
        font-size: 12px !important;
    }

    .marginsettings {
        margin-left: 4px !important;
        margin-top: 19px !important;
    }
}

@media (max-width: 899px) and (min-width: 818px) {
    .font-up-cards {
        font-size: 6.3px !important;
    }

    .font-down-cards {
        font-size: 8px !important;
    }

    .business-images {
        height: 43px !important;
        margin-top: 0px !important;
    }

    .icon_f {
        font-size: 15px !important;
    }

    .Name {
        font-size: 17px !important;
    }

    .dash-location {
        font-size: 7px !important;
    }

    .business-images-1 {
        height: 45px !important;
        margin-top: -1px !important;
    }

    .welcome-text {
        font-size: 14px !important;
    }

    .businesscards {
        height: 127px !important;
        width: 5.5rem !important;
    }

    .CardSize {
        height: 268.4px !important;
    }

    .profileImg {
        height: 38% !important;
    }

    .profileImg img {
        width: 36% !important;
    }

    .background-dashboard-color {
        display: flex;
        align-items: center;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 5.7rem !important;
        margin-right: 8px !important;
        height: 127px !important;
    }

    .view-button {
        padding: 6px 2px !important;
        font-size: 6px !important;
    }

    .btn-dash-sm {
        width: 68px !important;
        font-size: 9px !important;
    }

    .marginsettings {
        margin-left: 4px !important;
        margin-top: 11px !important;
    }
}

@media (max-width: 817px) and (min-width: 800px) {
    .font-up-cards {
        font-size: 6.3px !important;
    }

    .font-down-cards {
        font-size: 8px !important;
    }

    .business-images {
        height: 43px !important;
        margin-top: 0px !important;
    }

    .icon_f {
        font-size: 15px !important;
    }

    .Name {
        font-size: 17px !important;
    }

    .dash-location {
        font-size: 7px !important;
    }

    .business-images-1 {
        height: 45px !important;
        margin-top: -1px !important;
    }

    .welcome-text {
        font-size: 14px !important;
    }

    .businesscards {
        height: 127px !important;
        width: 4.9rem !important;
    }

    .CardSize {
        height: 268.4px !important;
    }

    .profileImg {
        height: 38% !important;
    }

    .profileImg img {
        width: 36% !important;
    }

    .background-dashboard-color {
        display: flex;
        align-items: center;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 5.1rem !important;
        margin-right: 8px !important;
        height: 127px !important;
    }

    .view-button {
        padding: 6px 2px !important;
        font-size: 6px !important;
    }

    .btn-dash-sm {
        width: 68px !important;
        font-size: 9px !important;
    }

    .marginsettings {
        margin-left: 4px !important;
        margin-top: 11px !important;
    }
}

@media (max-width: 799px) and (min-width: 768px) {
    .font-up-cards {
        font-size: 6.3px !important;
    }

    .font-down-cards {
        font-size: 8px !important;
    }

    .business-images {
        height: 43px !important;
        margin-top: 0px !important;
    }

    .icon_f {
        font-size: 15px !important;
    }

    .Name {
        font-size: 17px !important;
    }

    .dash-location {
        font-size: 7px !important;
    }

    .business-images-1 {
        height: 45px !important;
        margin-top: -1px !important;
    }

    .welcome-text {
        font-size: 14px !important;
    }

    .businesscards {
        height: 127px !important;
        width: 4.9rem !important;
    }

    .CardSize {
        height: 268.4px !important;
    }

    .profileImg {
        height: 38% !important;
    }

    .profileImg img {
        width: 36% !important;
    }

    .background-dashboard-color {
        display: flex;
        align-items: center;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 5.1rem !important;
        margin-right: 8px !important;
        height: 127px !important;
    }

    .view-button {
        padding: 6px 3px !important;
        font-size: 5px !important;
        margin-right: 3px !important;
    }

    .btn-dash-sm {
        width: 68px !important;
        font-size: 9px !important;
    }

    .marginsettings {
        margin-left: 4px !important;
        margin-top: 11px !important;
    }
}

@media (max-width: 767px) and (min-width: 520px) {
    .font-up-cards {
        font-size: 7px !important;
    }

    .font-down-cards {
        font-size: 9px !important;
    }

    .CardSize {
        margin: 20px 0px !important;
    }

    .profileImg img {
        width: 15% !important;
    }

    .Name {
        font-size: 18px !important;
    }

    .dash-location {
        font-size: 10px !important;
    }

    .business-images-1 {
        height: 45px !important;
        margin-top: 0px !important;
    }

    .businesscards {
        height: 142px !important;
        width: 6rem !important;
        margin-left: 31px !important;
    }

    .Cardadjust {
        width: 98% !important;
    }

    .background-dashboard-color {
        display: block !important;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 6.1rem !important;
        margin-right: 8px !important;
    }

    .view-button {
        padding: 6px 13px !important;
        font-size: 8px !important;
    }

    .btn-dash-sm {
        width: 73px !important;
        font-size: 12px !important;
    }

    .marginsettings {
        margin-left: 4px !important;
        margin-top: 3px !important;
    }
}

@media (max-width: 519px) and (min-width: 488px) {
    .font-up-cards {
        font-size: 7px !important;
    }

    .font-down-cards {
        font-size: 9px !important;
    }

    .CardSize {
        margin: 20px 0px !important;
    }

    .profileImg img {
        width: 15% !important;
    }

    .Name {
        font-size: 18px !important;
    }

    .business-images {
        margin-top: -1px !important;
    }

    .dash-location {
        font-size: 10px !important;
    }

    .business-images-1 {
        height: 45px !important;
        margin-top: 10px !important;
    }

    .businesscards {
        height: 142px !important;
        width: 6rem !important;
        margin-left: 31px !important;
    }

    .Cardadjust {
        width: 98% !important;
    }

    .background-dashboard-color {
        display: block !important;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 6.1rem !important;
        margin-right: 8px !important;
    }

    .view-button {
        padding: 6px 13px !important;
        font-size: 8px !important;
    }

    .btn-dash-sm {
        width: 73px !important;
        font-size: 12px !important;
    }

    .marginsettings {
        margin-left: 4px !important;
        margin-top: 3px !important;
    }
}

@media (max-width: 487px) and (min-width: 400px) {
    .font-up-cards {
        font-size: 7px !important;
    }

    .font-down-cards {
        font-size: 9px !important;
    }

    .CardSize {
        margin: 20px 0px !important;
    }

    .profileImg img {
        width: 15% !important;
    }

    .Name {
        font-size: 18px !important;
    }

    .business-images {
        margin-top: -1px !important;
    }

    .dash-location {
        font-size: 10px !important;
    }

    .business-images-1 {
        height: 45px !important;
        margin-top: 10px !important;
    }

    .businesscards {
        height: 142px !important;
        width: 5.5rem !important;
        margin-left: 31px !important;
    }

    .Cardadjust {
        width: 98% !important;
    }

    .background-dashboard-color {
        display: block !important;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 6.1rem !important;
        margin-right: 8px !important;
    }

    .view-button {
        padding: 6px 13px !important;
        font-size: 6px !important;
    }

    .btn-dash-sm {
        width: 73px !important;
        font-size: 12px !important;
    }

    .marginsettings {
        margin-left: 4px !important;
        margin-top: 3px !important;
    }
}

@media (max-width: 399px) and (min-width: 320px) {
    .font-up-cards {
        font-size: 7px !important;
    }

    .font-down-cards {
        font-size: 9px !important;
    }

    .CardSize {
        margin: 20px 0px !important;
    }

    .profileImg img {
        width: 15% !important;
    }

    .Name {
        font-size: 18px !important;
    }

    .business-images {
        margin-top: -1px !important;
    }

    .dash-location {
        font-size: 9px !important;
    }

    .business-images-1 {
        height: 45px !important;
        margin-top: 10px !important;
    }

    .businesscards {
        height: 142px !important;
        width: 5.5rem !important;
        margin-left: 31px !important;
    }

    .Cardadjust {
        width: 98% !important;
    }

    .background-dashboard-color {
        display: block !important;
        height: 93vh !important;
        margin-top: 53px !important;
    }

    .serviceColmn {
        width: 6.1rem !important;
        margin-right: 8px !important;
    }

    .view-button {
        padding: 6px 5px !important;
        font-size: 6px !important;
    }

    .btn-dash-sm {
        width: 73px !important;
        font-size: 12px !important;
    }

    .marginsettings {
        margin-left: 4px !important;
        margin-top: 3px !important;
    }
}