.input-styleone {
  border-color: #c6c6c6;
  border: 1px solid #B3B3B3 !important;
  box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.25) !important;
  border-radius: 6px;
  padding: 9px 10px;
  width: 90%!important;
  height: 19rem!important;
}

  .img_one-div{
    width: 40%!important;
    height: 10rem!important;
  }
