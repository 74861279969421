.postItemback {
    background-image: url("../../assets/images/Items/postback.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    margin-top: -10px;
}

.relative-up {
    margin: -19px 0px 0px 0px;
}

.postHead {
    color: white;
    margin-top: 40px;
    font-size: 16px;
    font-weight: 700;
}

.postPara {
    font-size: 13px;
}

.post-item-btn {
    background: #0353A4;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    color: white;
    font-size: 15px;
    padding: 2px 10px;
    font-weight: 600;
}

.box-container {
    border: 1px solid #ccc; /* Add a border to create a box */
    padding: 16px; /* Add padding to the box */
    border-radius: 8px; /* Add rounded corners */
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Add a shadow for depth */
  }
  .descriptionoverflow{
    overflow-wrap: break-word;
  }
  .promotion:hover{
    cursor: pointer;
  }


@media only screen and (min-width:768px) and (max-width:1199px){

    .post-item-btn{
        font-size: 12px;
        padding: 1px 10px;
        font-weight: 500;
    }
}