.button-table{
    display: flex;
  justify-content: flex-end;
}
.btns-table{
    /* width: 70px; */
    font-size: 11px;
    background-color: #267DCF;
    color: white;
    border: transparent;
    border-radius: 2px;
    padding: 4px 14px 4px 14px;
}

.btns-tables{
    /* width: 70px; */
    font-size: 10px;
    background-color:#D62828;
    color: white;
    border: transparent;
    border-radius: 2px;
    padding: 4px 14px 4px 14px;
}

@media (max-width: 991px) and (min-width: 768px) { 
    .btns-table{
        font-size: 9px;
    }
    .btns-tables{
        font-size: 9px;
    }


    
}

@media (max-width: 767px) and (min-width: 576px) { 
    .btns-table {
        width: 13%;
        font-size: 10px;
        
    }
    .btns-tables {
        width: 13%;
        font-size: 10px;
    }

    
    
}

@media (max-width: 575px) and (min-width: 367px) { 
    .btns-table {
        width: 12%;
        font-size: 10px;
    }
    .btns-tables {
        width: 12%;
        font-size: 10px;
    }

    
    
}


