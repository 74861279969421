.font-sm {
    font-size: 11px;
}

.font-up-cards {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #212121;
}

.form-font {
    font-size: 15px;
    font-weight: 400;
}

.font-down-cards {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    color: #212121;
}

.shop-Profile-font {
    font-size: 17px;
    font-weight: 600;
}

.shop-profile-para {
    font-size: 14px;
    color: #7B7B93;
    font-weight: 300;
}

.spaces {
    letter-spacing: 1px;
}

.icon_f {
    font-size: 20px !important;
}

.font-sm-15 {
    font-size: 14px;
}

.viewcandidate {
    color: #3D3BEE;
}

.nocandidate {
    color: #7B7B93;
    font-size: 11px;
}

.jobviewhead {
    font-size: 17px;
    font-weight: 500;
}

.custm-lble-2 {
    color: #212121;
    font-weight: 700 !important;
    font-size: 12px;
}

.createHeading {
    font-weight: 600;
    font-size: 19px;
    color: #212121;
}

.updateSize {
    font-weight: 500;
    font-size: 14px;
    color: #212121;
}

.cv-title {
    font-weight: 500;
    font-size: 14px;
    color: #424242;
    margin-bottom: 0px;
}

.cv-desc {
    font-weight: 500;
    font-size: 14px;
    color: #212121;
    margin-bottom: 5px;
}

.text-designation-color {
    color: #7B7B93;
    font-weight: 500;
    font-size: 14px;
}

.title-margin {
    margin-bottom: 0px;
    color: white;
    font-weight: 500;
    font-size: 14px;
}

.desc-margin {
    margin-bottom: 6px;
    color: white;
    font-weight: 500;
    font-size: 14px;
}

.font_11 {
    font-size: 11px;
}

.font_12 {
    color: #212121;
    font-weight: 600 !important;
    font-size: 12px;
}

.font_14 {
    font-size: 14px;
}

.font_13 {
    color: #212121;
    font-weight: 600 !important;
    font-size: 13px;
}

@media (width <=1199px) {
    .updateSize {
        font-size: 11px;
    }

}

/* @media (width >=992px) and (width <=1199px) {
    .shopnamesseett {
        font-size: 13px;
    }


} */