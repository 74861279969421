.img-5-Background {
    height: 119px;
    width: 110px;
}

.img-5-Background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}


/* .template-5-back {
    background: #1E2126;
    border-radius: 8px;
  
} */


.profilescroll-5 {
    max-height: 66.7vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
}

.profile-bio-scroll-5 {
    max-height: 64.4vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 25px;
    margin-left: 20px;
}

.cv-5-bio {
    background: #1E2126;
    border-radius: 8px;
  
}

.yellow-back {
    background: #EAC31D;
    border-radius: 0px 0px 8px 8px;
    padding: 17px 0px;
}



.sideLine {
    height: 67vh;

}

.sideLine img {
    height: 100%;
    padding-top: 20px;
}

.yellowdown-back {
    background: #EAC31D;
    border-radius: 8px 8px 0px 0px;
    height: 34px;
}
@media only screen and (min-width:768px) and (max-width:906px) {
    .cv-header-5 {
        font-size: 13px!important;
    }
    .title-margin-5 {
        font-size: 11px!important;
    }
    .desc-margin {
        font-size: 11px!important;
    }
    .profile-bio-scroll-5 {
        margin-left: 8px!important;
    }
    .sideLine {
        margin-left: 4px!important;
    }
}