.cv-modal-btn1{

    background-color: #EE3B3B;
    padding: 2px 30px 2px 30px;
    border: transparent;
    border-radius: 3px;
    color: white;

}

.cv-modal-btn2{

    background-color: #0353A4;
    padding: 2px 25px 2px 25px;
    border: transparent;
    border-radius: 3px;
    color: white;

}



.cv-modaal-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 3px solid #0353A4;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

}

.cv-modal-head{

    background-color: #0353A4;

}
