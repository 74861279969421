.cards-shadow {
    background: #FFFFFF !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 8px !important;

}

.custom-h {
    height: 45%;
}

.Rosie {

    font-size: 20px;
    font-weight: 700;
}

.dashboardbussiness {
    margin-top: 4px;
}

.classsone {
    background: #FFFFFF;
    border: 2px solid #020094;
    border-radius: 4px;
    font-size: 14px;
}

.classstwo {
    background: #020094;
    border: transparent;
    box-shadow: inset 6px 6px 6px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: white;
    font-size: 14px;
    font-weight: 400 !important;
}

.classsSell {
    border-radius: 4px;
    color: #020094;
    font-weight: 400 !important;
    font-size: 14px;
    background-color: #FFFFFF;
}

.Butoonn {
    font-size: 14px;
    padding: 4px !important;
    border: 2px solid #020094 !important;
    border-radius: 4px !important;
    width: 100px !important;

}

.classsbuy {
    background: #020094;
    border: 2px solid #020094;
    box-shadow: 1px 6px 6px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    color: white;
    font-size: 14px;
    font-weight: 400 !important;
}

.Butoonn2 {
    font-size: 14px;
    padding: 4px !important;
    border-radius: 4px;
    font-weight: 500;
    width: 100px !important;

}

.bttn {

    background-color: #0353A4;
    padding: 8px 2px !important;
    font-size: 12px !important;


}

.bttnn {

    padding: 4px !important;
    background-color: #0353A4;
    border-radius: 5px !important;
    width: 100px !important;
    font-size: 15px !important;
}

.dash-btn {
    background: #FFFFFF;
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;
    padding: 6px 8px;
    border: 1px solid #111111;
    border-radius: 20px;
}

.dash-btn:hover {
    background-color: #020094;
    color: white;
}

.karachi-font {

    color: #7B7B93;
    font-size: 13px;
    padding-bottom: 20px !important;

}

.verify {

    color: #7B7B93;
    font-weight: 400;
    font-size: 15px;
    padding-left: 0px;

}

.btn-col {

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 4vh !important;
    padding-right: 12%;

}

.karachi-main {

    display: flex;
    justify-content: center;

}


@media (max-width: 991px) and (min-width: 768px) {

    .btn-col {

        padding-right: 0px !important;

    }

    .bttn {

        width: 60% !important;

    }

    .karachi-font {

        padding-right: 3vh !important;

    }

    .karachi-main {

        display: flex;

    }

    .dash-btn {
        font-size: 12px;
        padding: 4px 7px 6px 7px;
    }

    .business_avataar {
        width: 36%;
    }
}

@media (width >=767px) and (width <=778px) {
    .dash-btn {
        font-size: 11px;
    }
}

@media (width >=551px) and (width <=575px) {
    .dash-btn {
        font-size: 11px;
    }

    .business_avataar {
        width: 30%;
        margin-top: 14px;

    }

}






@media (max-width: 767px) and (min-width: 685px) {

    .bttn {

        width: 60% !important;

    }

    .karachi-main {

        display: flex;

    }

    .karachi-font {

        padding-right: 7vh !important;

    }
}

@media (max-width: 684px) and (min-width: 568px) {

    .bttn {

        width: 60% !important;

    }

    .karachi-main {

        display: flex;

    }

    .bttn {

        padding: 6px !important;
        font-size: 11px !important;
        width: 100% !important;

    }

    .karachi-font {

        padding-right: 7vh !important;

    }

}

@media (max-width: 567px) and (min-width: 480px) {

    .bttn {

        font-size: 10px !important;
        padding: 7px !important;

    }

    .karachi-main {

        display: flex;

    }

    .bttn {

        font-size: 9px !important;
        padding: 5px !important;

    }

}

@media (max-width: 550px) and (min-width: 404px) {

    .btn-col {
        display: flex;
        align-items: flex-start;

    }

    .avataar {
        width: 30%;
        margin-top: 16px;
    }

    .btn-clr {
        font-size: 10px !important;
        width: 95px !important;
    }

    .font {

        font-size: 12px !important;
        font-weight: bolder !important;
    }

    .Butoonn {

        font-size: 12px !important;
        padding: 4px !important;
        width: 80% !important;

    }

    .Butoonn2 {

        font-size: 10px !important;
        padding: 3px !important;
        width: 80% !important;
    }

    .bttnn {

        font-size: 10px !important;
        padding: 4px !important;
        width: 80% !important;
    }

    .bttn {

        font-size: 7px !important;
        padding: 6px !important;
        width: 80% !important;
        border-radius: 3px !important;

    }

    .karachi-font {

        font-size: 12px;

    }

    .verify {

        font-size: 12px;

    }

    .Rosie {

        font-size: 17px !important;

    }

    .dash-btn {
        background: #FFFFFF;
        border-radius: 6px;
        font-weight: 500;
        font-size: 11px;
        padding: 5px 4px;
        border: 1px solid #111111;
    }

    .title-size {
        font-size: 11px !important;
        font-weight: 700 !important;
    }

}

@media (max-width: 403px) and (min-width: 320px) {

    .btn-col {
        display: flex;
        align-items: flex-start;

    }

    .cards-shadow {
        height: 212px !important;
        margin-top: 10px;
    }

    .btn-clr {
        font-size: 8px !important;
        padding: 4px !important;
        width: 74px !important;
    }

    .Butoonn {
        font-size: 11px !important;
        padding: 4px !important;
        width: 50% !important;

    }

    .font {

        font-size: 9px !important;
        font-weight: bolder !important;
    }

    .verify {
        font-size: 12px !important;
    }

    .bttnn {

        font-size: 10px !important;
        padding: 4px !important;
        width: 90% !important;
    }

    .bttn {

        font-size: 7px !important;
        padding: 5px 3px !important;
        width: 90% !important;
        border-radius: 3px !important;

    }

    .karachi-font {

        font-size: 8px;

    }

    .Rosie {

        font-size: 12px !important;

    }

    .avataar {
        width: 35%;
        margin-top: 14px;
    }
}

.Butoonn {

    border: transparent;
    width: 100px !important;
    /* margin: auto; */

}

@media (max-width: 1199px) and (min-width: 1024px) {

    .Butoonn2 {

        font-weight: 400;
        width: 100px !important;

    }

    .bttn {

        background-color: #0353A4;
        /* width: 100%; */
        padding: 3px 10px !important;
        font-size: 9px !important;
        font-weight: bolder !important;

    }

    .bttnn {

        padding: 6px !important;
        background-color: #0353A4;
        border-radius: 5px !important;
        width: 100px !important;
        /* margin: right; */
        font-size: 14px !important;
    }

    .verify {

        color: #7B7B93;
        font-size: 13px;
        padding-left: 0px;

    }

    .karachi-font {
        color: #7B7B93;
        font-size: 13px;

    }

    .karachi-main {
        display: flex;
        /* justify-content: space-between; */

    }

    .cards-shadow {
        width: 105% !important;

    }

    .business_avataar {
        width: 32%;
    }

}

@media (max-width: 1023px) and (min-width: 992px) {

    .Butoonn {

        padding: 2px 3px !important;
        width: 100px !important;

    }

    .Butoonn {
        padding: 2px !important;
        color: #0353A4;
        border: transparent;
        border-radius: 5px !important;
        width: 100px !important;

    }

    .Butoonn2 {

        padding: 2px !important;
    }

    .bttn {

        background-color: #0353A4;
        padding: 3px 2px 4px 0px !important;
        font-size: 11px !important;

    }

    .bttnn {

        padding: 2px !important;
        font-size: 11px !important;
    }

    .karachi-font {
        font-size: 11px;
        padding-bottom: 20px !important;

    }

    .verify {


        font-size: 11px;

        /* padding-bottom: 40px; */

    }

    .avataar {

        width: 80px;
        height: 80px;

    }

    .business_avataar {
        width: 42%;
    }

}

@media (width <=767px) {
    .dash-btn {
        width: 80% !important;

    }

    .dashbtnnn {
        text-align: center;
    }
}

@media (width <=575px) {
    .dash-btn {
        width: 80% !important;


    }

    .dashbtnnn {
        text-align: center;
    }
}

@media (width <883px) {
    .dash-btn {
        font-size: 11px;
        padding: 5px 7px 5px 7px;
       border-radius: 15px;

    }

}

@media (width <=915px) {
    .dash-btn {
        margin-top: 8px !important;
    }

}

@media (width <=799px) {
    .dash-btn {
        margin-top: 10px !important;
    }

}