
/* for background-color */
.bg-red {
    background-color: #d62828;
}

.bg-blue {
     background-color: #0353A4;
}
.bg-blue-lg {
     background: #0353A4;
}
.bg-btn-primary {
     background: #3d3bee !important;
}

.bg-dark {
     background-color: #061a40;
}
.bg-light {
     background-color: #b9d6f2;
}
.background-white{
     background-color:#FFFFFF;
}

.background-dashboard{
     background: #EDEDED;
}

.bg-grey{
     background-color: #7b7b93;
}
.bg-vilot{
     background-color: #E9E9E9;
}

/* for text color */


.font-red {
     color: #d62828;
 }
 
 .font-blue {
      color: #3D3BEE;
 }
 
 .font-primary {
      color: #0353a4;
 }
 
 .font-dark {
      color: #061a40;
 }
 
 .font-light {
      color: #b9d6f2;
 }
 .font-navy{
    color: #3d3bee
 }
.font-grey{
     color:#7b7b93;
}
.font_black{
     color: #212121 !important;
}

.bg-black-2{
     background-color: #212121 !important;
}
 