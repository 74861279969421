@media only screen and (min-width:1347px) and (max-width:1404px){
    .btn-red-sm {
        padding: 8px 0px!important;
        width: 109px!important;
    }
    .btn-blue-sm {
        padding: 8px 0px!important;
        width: 109px!important;
    }
}
@media only screen and (min-width:1255px) and (max-width:1346px){
    .btn-red-sm {
        padding: 8px 0px!important;
        width: 100px!important;
    }
    .btn-blue-sm {
        padding: 8px 0px!important;
        width: 100px!important;
    }
}
@media only screen and (min-width:1200px) and (max-width:1255px){
    .btn-red-sm {
        padding: 7px 0px!important;
        width: 95px!important;
    }
    .btn-blue-sm {
        padding: 7px 0px!important;
        width: 95px!important;
    }
}