.payment {
    background-color: #267DCF;
    color: white;
    margin: 0px;
    width: 100%;
    font-size: 12px;
    padding: 4px 0px 4px 0px;

}

.paymentinput {
    background-color: #F2F2F2;
    border: transparent;
    width: 100%;
    border: 1px solid #222222B2;
}

.inputarea {
    padding: 9px 10px;
    border: none !important;
    font-size: 11px;
    background-color: #F2F2F2;
    color: black;
    width: 100%;
    height: 12vh !important;
    font-weight: 700;
}

.terms-and-conditions-class{
    font-size: 11px;
    font-weight: 700;
}

.pay {
    background-color: #267DCF;
    color: white;
    width: 100%;
    margin: 0px;
}

.tablee {
    width: 100% !important;
    height: 10px !important;
}

.tablebody {
    background-color: #F2F2F2;
    border: 1px solid #222222B2;
}

.hgt {
    font-size: 13px;
    width: 50%;
    text-align: center;;
}

.Description {
    border: 1px solid #222222B2;
    border-radius: 4px;
    background-color: #F2F2F2;
    width: 100%;
    font-size: 11px;
    line-height: 14px;
}

.TermsConditionss {
    width: 100% !important;
    background-color: #F2F2F2;
    border: 1px solid #222222B2;

}


.inputareabottom {
    border-color: #c6c6c6;
    border: none !important;
    font-size: 12px;
    background-color: #F2F2F2;
    color: #7b7b93;
    width: 100%;
    height: 80px;
    line-height: 15px;
}

.amount-due{

    border: 1px solid #222222B2;

}

.tr-class-for-row{

    border: 1px solid #222222B2;
    border-radius: 6px;

}

@media (max-width: 991px) and (min-width: 768px) {
    .payment {
        width: 100%;
    }

    .paymentinput {
        width: 100%;
    }


    .pay {
        width: 100% !important;
    }

    .tablee {
        width: 100% !important;
        /* height: 138px !important; */
    }

    .inputareabottom {
        width: 100% !important;
        height: 100%;
    }

    .TermsConditionss {
        width: 100% !important;

    }


}

@media (max-width: 767px) and (min-width: 576px) {
    .inputarea {
        width: 234px;
        height: 86px;
    }

    .payment {
        width: 100%;
    }

    .paymentinput {
        width: 100%;
    }

    .pay {
        width: 100%;
    }

    .tablee {
        width: 100% !important;
        height: 171px !important;
    }

    .TermsConditionss {
        width: 100% !important;
    }

    .inputareabottom {
        width: 100%;
        height: 90px;
    }

    .Description {
        width: 100%;
    }
}

@media (max-width: 575px) and (min-width: 367px) {

    .payment {
        width: 97%;
    }

    .paymentinput {
        width: 100%;
    }

    .inputarea {
        width: 100%;
        height: 70px;
    }

    .pay {
        width: 97%;
        margin-top: 2vh;
    }

    .tablee {
        width: 97% !important;
        height: 10px !important;
    }

    .inputareabottom {
        width: 100%;
        height: 100px;
    }


    .Description {
        width: 97%;
        height: 105px;
    }

    .TermsConditionss {
        width: 94% !important;
    }

}