.overflowData {
  height: 28vh;
  overflow: auto;
}

.font_small_profile {
  font-size: 10px;

}

.icon_f_sm {
  font-size: 15px !important;
}

.btnns {

  display: flex;
  flex-direction: column;
  border-radius: 2px;
}