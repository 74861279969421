.postion-absloute {
    position: absolute;
    left: 13%;
    height: 80vh;
    top: 12%;
}

.img-fitt {
    object-fit: cover;
    height: 300px;
    width: 50%;
    overflow: hidden;
}

.arrow-wd {
    padding: 0px 0px 2px 0px;
    width: 13px;
    cursor: pointer;
}

.description-font-size {
    font-size: 14px;
}

.Model_btn {
    width: 17px;
    padding: 2px 2px;
    border-radius: 20px;
    background: white;
}
.imageGallerySize{
    height: 90%;
}
.image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain;
    height: 543px!important;
}
.image-gallery-left-nav {
    left: 50px!important;
}
.image-gallery-right-nav {
    right:50px!important;
}
.shareItembtn{
    border: 1px solid #0353A4;
    background-color: transparent;
    font-size: 11px;
    color: #0353A4;
    border-radius: 6px;
}