.lmt-smt {
  background: #1c1b1b;
  border-radius: 8px;

  
}
.scroll-area{
  overflow-y: scroll;
  /* height:72vh; */
}

.noscroll-al{
  overflow-y: scroll;
  height: 63.5vh;
}
.dlt-slt {
  width: fit-content;
  height: fit-content;
  left: 1365px;
  top: 184px;

  background: #000000;
  border-radius: 8px 8px 8px 0px;
  font-size: 14px;
}

.you-yet {
  width: fit-content;
  height: fit-content;
  /* left: 1711px;
  top: 442px; */
  background: #302d2d;
  border-radius: 8px 8px 8px 0px;
  font-size: 14px;
}

.wri-te {
    width: 95%;
    height: 59px;
    left: 1365px;
    top: 184px;

  background: #000000;
  border-radius: 8px;
}

.you-icn {
  width: 43px;
  height: 53px;
  left: 1651px;
  top: 900px;

  background: #0353a4;
  border-radius: 8px;
}
.textMEssage{
  background-color: transparent!important;
  border: none!important;
  color: white!important;
}

@media (max-width: 1023px) and (min-width: 992px) { 
  .noscroll-al{
    overflow-y: scroll;
    height: 60vh;
  }

  .you-icn {
    width: 38px;
    height: 49px;
  }
  .amt-qwt{
    margin-left: 9px;
  }
}

/* @media (max-width: 1400px) and (min-width: 1200px) {
  .noscroll-al{
    overflow-y: scroll;
    height: 62.5vh;
  }

} */

@media (max-width: 1199px) and (min-width: 1024px) { 
  .noscroll-al{
    overflow-y: scroll;
    height: 62.5vh;
  }
  .amt-qwt{
    margin-left: 9px;
  }
} 

@media (max-width: 1399) and (min-width: 1200) {
  .noscroll-al{
    
    height: 59.5vh;
  }
}

