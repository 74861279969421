.Arrow{
    font-size: 35px !important;
}

.MODLBTTON{  
    display: flex;
    justify-content: center;
}

.MODLBTTON1{
    display: flex;
    justify-content: center;
}

