.CVtexts h6 {
    color: #212121;
    font-weight: 500;
    font-size: 26px;
    margin-bottom: 2px;
    padding-left: 3px;
}
.CVtexts h5 {
    color: #3D3BEE;
    font-weight: 500;
    font-size: 28px;
}
.leftSideCenter{
    height: 91vh;
}
.centerline{
    height: 91vh;
    background: #C4C4C4;
    width: 2px;
    margin: 0px;
}
.cvcenterline{
    background: #C4C4C4;
    width: 2px;
    height: 91vh;
    margin: 0px;
}

.name-back{
    background: #211F28;
}
.slick-prev:before {
    font-family: 'slick';
    font-size: 30px!important;
    line-height: 1;
    opacity: .75;
    color: black!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-next:after{
    font-family: 'slick';
    font-size: 30px!important;
    line-height: 1;
    opacity: .75;
    color: black!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
}
.slick-next:after {
    content: '→';
}
.slick-next {
    right: 28%!important;
}
.slick-next:before{
    content: '';
}
.slick-prev {
    /* left: -25px; */
    left: -9%!important;
    margin-top: 15px!important;
}