 .Coverimage_dp {
   position: absolute;
   top: -50px;
   left: 70%;
   background: #ffffff;
   border-radius: 4px;
 }

 .coverImgSize {
   width: 100%;
   object-fit: cover;
   overflow: hidden;
   height: 185px;
 }

 .Coverimage {
   height: 185px;
   position: relative;

 }

 .dragImg img {
   width: 40px;
 }

 .absolute-setting {
   margin: 70px -17px 0px 0px;
   z-index: 2;
 }