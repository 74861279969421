.page-link {
  font-size: 12px !important;
}

.page-link:focus {
  box-shadow: none !important;
}

.folow-btn {
  border: 1px solid #0353a4;
  color: white;
  padding: 0px 10px 0px 10px !important;
  font-size: 10px;
  border-radius: 50px;
  margin-top: 0.7rem;
  background-color: #0353a4 !important;
}

/* //additional setting */

.search-container {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 1px;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
  background-color: rgba(3, 83, 164, 1);
  width: 12%;
  height: 2rem;
}

.serachSEt {
  padding: 6px !important;
  border-radius: 5px !important;
  font-size: xx-large !important;
  width: 13% !important;
}

