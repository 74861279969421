.scroll-container li {
    margin-bottom: .7rem;
}

.display-setting {
    flex-wrap: inherit !important;
    padding: 2px;
    width: 420px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    border: none !important;

}

.timelinescrollTwo {
    overflow-y: auto;
    max-height: 80.9vh;
    padding-bottom: 23px;
}

.display-setting-2 {
    flex-wrap: inherit !important;

    padding-bottom: 0px;
    width: 630px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    border: none !important;
}


.nav-tabs .nav-link.active {
    color: #3d3bee !important;
    border-bottom: 2px solid #3d3bee !important;
}

.nav-tabs .nav-link {
    color: #7b7b93;
    border: none !important;
    font-size: 14px;
}

.dottsBox {
    position: absolute;
    top: 28%;

    box-sizing: border-box;
    right: 10%;
}

.absoluteStyling {
    position: absolute;
    right: .5rem;
    margin: -44px 0px 0px 0px;
}


.border_3 {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    background-color: white !important;
}

.Comet {
    cursor: pointer;
}

.Share-btn {
    cursor: pointer;
}

.tabbable .nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
}

.tabbable .nav-tabs .nav-link {
    white-space: nowrap;
}

.subscriptionimg {
    position: absolute;
    width: 19px;
    top: 18%;
    left: 26%;

}

@media (width <=349px) {

    .subscriptionimg {
        left: 19%;
    }
}


@media (width >=350px) and (width <=400px) {

    .subscriptionimg {
        left: 23%;
    }
}


@media (width >=410px) and (width <=500px) {

    .subscriptionimg {
        left: 28%;
    }
}

@media (width >=501px) and (width <=635px) {

    .subscriptionimg {
        left: 32%;
    }
}




@media (width >=636px) and (width <=992px) {

    .subscriptionimg {
        left: 36%;
        top: 18%;
    }
}

@media (width >=993px) and (width <=1200px) {

    .subscriptionimg {
        left: 24%;
        top: 13%;
    }
}

@media (width >=1400px) {

    .subscriptionimg {
        left: 29%;
    }
}