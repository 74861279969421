.bg_purple {
  background: #4B123E !important;

}

.livechat-searchbtn {
  background: white;
  border-radius: 6px;
  padding: 2px 15px 2px 15px;
  font-size: 13px;
}

.livechat-searchbtn::placeholder {
  color: #0fc2c0;
  font-size: 13px;
}

.invitechatimg {
  border-radius: 50%;
}

.invite-cards {
  background: #267DCF !important;
}

.invitechatimg {
  height: 70px;
  width: 70px !important;
}

.img--invite {
  vertical-align: middle;
  width: 70px;
  height: 70px;
  margin: auto;
  border-radius: 50%;
  object-fit: cover;
}

.scroll-chatroom {
  height: 83vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 14px 0px 17px 0px;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.ss::placeholder {
  color: #0fc2c0;
}