.candidateImg {
  height: 47px;
  width: 47px;
}
.candidateImg img{
  border-radius: 50%;
}
.candidate-card-p{
  width: 12rem;
}
.candidateName {
    font-size: 13px;
}
.connect-btn-1{
    border: 1px solid #EE3B3B;
    color: #EE3B3B;
    font-size: 10px;
    border-radius: 50px;
}
.mdl-clss {
    background-color: #0353a4!important;
    color: white !important;
}
.circle-icon{
        color: #0353A4 !important;
        font-size: 85px !important;
}



.connect-btnignore {
  border: 1px solid #e91c37;
  color: #da1546;
  padding: 0px 8px 0px 10px;
  font-size: 8px;
  border-radius: 50px;
}

.connect-btnconectbck {
  border: 1px solid #3d3bee;
  color: #3d3bee;
  padding: 0px 11px 0px 11px;
  font-size: 8px;
  border-radius: 50px;
  margin-top: 0.7rem;
}

.border-cardset {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: rgba(99, 99, 99, 0.2) 1px 11px 16px 4px;
    height: 100%;
}

.manage-height{
  height: 40vh;
  overflow-y: auto;
}
