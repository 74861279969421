.heading{
    height: 48px;
    /* border-radius: 5px; */
    background-color: #267DCF;
}

.closeicon{
    color: white;
    font-size: 20vh;
}

.inputss {
    border-color: #c6c6c6;
    border: 1px solid #B3B3B3 !important;
    border:transparent !important;
   
    padding: 9px 10px;
    font-size: 12px;
    color: #7b7b93;
}

.headerss{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 10px 3px #E7E8FB;
    border-radius: 8px;
}

.inputs{
    border-radius: 0px !important;
    font-size: 16px ! important;
}

.bottomicon{
   
    background-color: #E7E8FB;
}

.massage{
    color: white;
}