.img-4-Background {

    width: 100%;
    height: 260px;
}

.img-4-Background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 25px 30px 0px 35px;
}

.profilescroll{
    max-height: 54.4vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
}

.profile-bio-scroll-4 {
    max-height: 53vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 25px;
    margin-left: 20px;
}

.cv-4-bio {
    background: #E3E2FF;
    border-radius: 0px 4px 0px 0px;
    height: 260px;
}
.text-bio{
    color: #3D3BEE;
}
.cv-4-line {
    width: 3px;
    height: 53vh;
    background-color: #C4C4C4;
}
