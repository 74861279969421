.postion-absloute {
    position: absolute;
    left: 13%;
    height: 80vh;
    top: 12%;
}

.img-fitt {
    object-fit: cover;
    height: 300px;
    width: 50%;
    overflow: hidden;
}

.arrow-wd {
    padding: 0px 0px 2px 0px;
    width: 13px;
    cursor: pointer;
}

.description-font-size {
    font-size: 14px;
}

.Model_btn {
    width: 17px;
    padding: 2px 2px;
    border-radius: 20px;
    background: white;
}

.rating-stars{
 justify-content: space-around;
}

.phgh{
    font-size: 13px;
}

.card-scrolll{
    height: 430px;
    overflow-y: scroll;
    overflow-x: hidden ;
}

.card-scrolll::-webkit-scrollbar {
    display: none;
}