.transport-btn-call {
  padding: 2px 30px 2px 30px;
  border: transparent;
}
.btns-connect {
  background-color: #0353a4 !important;
  color: white !important;
  width: 70% !important;
  border: transparent !important;
  border-radius: 7px;
}
.first_divmain {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.arrow_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.share_btton {
  border: 1px solid #0353a4;
  background-color: transparent;
  font-size: 11px;
  color: #0353a4;
  border-radius: 6px;
  padding: 0px 10px 0px 6px;
}
.name_qrset {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 14px 0px 0px;
}
.sugetion_btns_fit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 9px 0px 4px;
}
.call_bttnset {
  width: 158px;
  height: 32px;
  background-color: #3d3bee;
  color: white;
  border-radius: 6px;
  border: none;
}
.loop_starset {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_relativdiv {
  position: relative;
}
.main_absolutediv {
  position: absolute;
  z-index: 1;
  /* width: fit-content; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.input_searchsett {
  width: fit-content;
}
.input_hiegtseet {
  height: 27px;
}
.button_serachsett {
  padding: 1px 16px;
  background-color: #020094;
  border: none;
}
