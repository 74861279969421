@media (max-width: 424px) and (min-width: 320px){
    .titleText {
        font-size: 9px!important;
    }
    .custm-lble {
        font-size: 8px !important;
    }
    .form-select {
    
    }
    .labelText {
        font-size: 10px!important;
    }
    .btn-blue-sm {
        font-size: 11px!important;
        width: 98px!important;
    }
}