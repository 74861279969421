.availble_bt0n {
  background-color: white;
  position: absolute;
  padding: 0px 3px;
  /* left: 82%; */
  border-radius: 6px;
  outline: auto;
  outline-color: rgb(106 235 64);
  align-items: center;
  margin: 2px;
  right: 0px;
  top: 0px;
}
.availble_bt0n span {
  color:#1D800D;
}
