.screenHeight {
  height: auto;
  margin-top: 59px;
}
a:hover{
  text-decoration: none !important ;
}
.number-disapper [type="number"] {
  -moz-appearance: textfield;
}

.number-disapper::-webkit-outer-spin-button,
.number-disapper::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ShopsCards,
.ShopsCards_2 {
  box-shadow: 0px 4px 6px 3px #e7e8fb;
}

.myaccordain .accordion-button {
  font-size: 15px !important;
}

.servicesHeight {
  height: 91vh;
}

.fix_img {
  height: 50px;
  width: 50%;
  object-fit: cover;
  margin: auto;
}


.disable-btn[disabled] {
  pointer-events: auto !important;
}

.custom-modal-header {
  border: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background-color: #3d3bee;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  position: absolute;
  right: 2%;
  top: -2rem;
  background-color: #e7e8fb;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  opacity: 0.9;
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.loaderHeight {
  height: 60vh;
}

.request_height {
  height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.none {
  display: none;
}

.block {
  display: block;
}

.height_100 {
  height: 100% !important;
}

.container {
  overflow-y: hidden;
  overflow-x: hidden;
}

.input-style {
  border-color: #c6c6c6;
  border: 1px solid #b3b3b3 !important;
  box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.25) !important;
  border-radius: 6px;
  padding: 9px 10px;
  font-size: 12px;
  color: black;
}

.input-style:focus {
  border-color: none;
}

.input-style::placeholder {
  font-size: 12px;
  /* color: #7b7b93; */
}

textarea {
  resize: none !important;
}

.Form-Shadow {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 10px 3px #e7e8fb;
  border-radius: 6px;
}

.Name {
  color: #212121;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 23px;
}

.businesscards {
  margin-bottom: 13px;
  height: 142px;
  width: 8.9rem;
}

.Cardadjust {
  height: 100% !important;
  width: 96%;
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3) !important;
  border-radius: 8px !important;
}

.cardsettingss {
  box-shadow: 4px 10px 7px rgba(0, 0, 0, 0.3);
  background: #ffffff !important;

}

.CardSize {
  height: 296.4px;
}

.buyingCardsSpace {
  padding: 0px 12.7px 0px 11.5px;
}

.s_images {
  object-fit: cover !important;
  width: 100% !important;
  height: 90px !important;
  display: flex !important;
  justify-content: center !important;
}

.s_images img {
  width: 35% !important;
}

.c_images {
  object-fit: cover !important;
  width: 100% !important;
  height: 90px !important;
  display: flex !important;
  justify-content: center !important;
}

.c_images img {
  width: 90% !important;
}

.box-items-width {
  width: 23.9%;
}

.card-img {
  width: 100%;
  object-fit: cover;
  height: 128px;
}

.scroll-w {
  height: 47vh;
  overflow-y: auto;
}

.scroll-shops-business {
  height: 80vh;
  overflow-y: auto;
}

.position_r {
  position: relative;
}

.shop-scroll {
  height: 53vh;
  overflow-y: auto;
  overflow-x: hidden;
}

ul {
  list-style: none;
}

.services-scroll {
  height: 40vh;
  overflow-y: auto;
}

.Property-scroll {
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-w::-webkit-scrollbar {
  display: none;
}

.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: contain;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 40px;
}

.div-width {
  width: 50%;
}

.div-width-1 {
  width: 45%;
}

.mangeyourprofilee {
  font-size: 20px;

}

.shop-profile-box {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 10px 3px #e7e8fb;
  border-radius: 6px;
  height: 100vh;
}

.shop-profile-img {
  height: 200px;
  object-fit: cover;
}

.custm-lble {
  color: #212121;
  font-weight: 700 !important;
  font-size: 11px;
}

.custom-fix-one {
  box-shadow: inset 0px 0px 2px 1px rgba(17, 17, 17, 0.25);
  border-radius: 4px;
  font-weight: bolder;
}

.bg-primary-one {
  background-color: #004183;
}

.font-weightone {
  font-weight: 600;
}

.background-image {
  background-image: url("/src/assets/images/filterationForm\ Img/BGimgone.png");
  background-repeat: no-repeat;

  background-position: center;
}

.labelText {
  font-size: 11px;
  color: #7b7b93;
  font-weight: 500;
}

.labelText2 {
  font-size: 11px;
  font-weight: 500;
}

.iconWhite {
  color: white;
  font-size: 20px !important;
}

.titleText {
  /* color: #3D3BEE; */
  font-size: 13px;
  font-weight: 700;
  color: white;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 0rem;
  height: 0rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-item {
  height: 200px;
}

.custm-light {
  color: #212121;
  font-weight: 400 !important;
  font-size: 12px !important;
}

.blackBack {
  background: #7b7b93;
}

.blueBack {
  background: #3d3bee;
  border-radius: 0px 6px 6px 0px;
}

.inputSetting input {
  width: 100%;
  box-shadow: inset 0px 0px 8px 4px rgba(17, 17, 17, 0.25);
  border-radius: 4px;
}

.form-select {
  font-size: 12px;
}

.form-select-seller {
  padding: 13px !important;
}

.form-control::placeholder {
  font-size: 12px !important;
}

.form-control {
  font-size: 12px !important;
}

.form-control-seller {
  padding: 13px !important;
}

.font_small {
  font-size: 12px;
}

.personImgSize {
  height: 40px;
  width: 40px;
}

.personImgSize img {
  border-radius: 50%;
  border: 2px solid #0353a4;
  height: 50px;
  width: 50px;
  overflow: hidden;
  object-fit: cover;
}

.connect-btn {
  border: 1px solid #0353a4;
  color: white;
  font-size: 9px;
  border-radius: 50px;
  background-color: #0353a4;
}

.connect-btn-one {
  border: 1px solid #dd0012;
  color: #dd0012;
  font-size: 10px;
  border-radius: 50px;
}

/* @media only screen and (min-width: 1360px) and (max-width: 1399px) {
  .connect-btn {
    width: 35%;
    font-size: 10px;
    height: 6vh;
}
  .connect-btn-one{
    width: 43%;
    font-size: 10px;
    height: 6vh;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1463px){
  .connect-btn, .connect-btn-one{
    width: 35%;
    font-size: 10px;
    height: 6vh;
}
}

@media only screen and (min-width: 1464px) and (max-width: 1520px){
  .connect-btn, .connect-btn-one{
    width: 35%;
    font-size: 10px;
    height: 6vh;
}
} */

.ShopsCards_business {
  width: 12rem;
  box-shadow: 0px 4px 6px 3px #e7e8fb;
}

.w-img {
  width: 20px;
}

.w-imgone {
  width: 20px;
}

.imagesetttttt {
  width: 65%;
  height: 100%;
  object-fit: cover;
  padding: 5px;
}

.chatBorderLine {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 1px 0px 4px 0px;
}

.border_2 {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 100%;
}

.border_3 {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

textarea:focus {
  box-shadow: none !important;
}

.timelinescroll {
  overflow-y: auto;
  max-height: 54vh;
  padding-bottom: 23px;
}

select:focus {
  box-shadow: none !important;
}

.profile_img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.font_exsmall {
  font-weight: 700;
  font-size: 10px;
}

.font-sm-x {
  font-size: 12px;
}

.applicationText {
  color: #7b7b93;
  font-size: 13px;
}

.items-card-font {
  font-size: 12px;
}

.scrollable-connect {
  height: 50vh;

  overflow-y: scroll;
}

.share_icon_f {
  font-size: 12px !important;
}

.share_font {
  font-size: 9px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.cursor {
  cursor: pointer;
}

.error_text_color {
  color: red !important;
  font-size: 13px !important;
  padding-top: 2px !important;
}

.scroll-shops {
  height: 44vh;
  overflow-y: auto;
}

.scroll-shops_2 {
  height: 66.5vh;
  overflow-y: auto;
}

.applicationText {
  color: #7b7b93;
  font-size: 13px;
  max-width: 100%;
  word-wrap: break-word;
}

.items-card-font {
  font-size: 12px;
}

.cardsettingss {
  border-radius: 6px;
  border: 1px solid #0000001A !important;
}

.cardsettingss:hover {
  border: 1px solid rgba(6, 85, 233, 0.993) !important; 
  box-shadow: rgba(6, 86, 233, 0.555) 0px 5px 15px;
  cursor: pointer;
  transition: transform .9s;
  transform: scale(1.05);

}

@media only screen and (min-width: 1360px) and (max-width: 1399px) {
  .ShopsCards {
    width: 10rem !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mob_f {
    font-size: 10px !important;
  }
}

.line-height {
  line-height: 1.4;
}

.candidateOccupation {
  color: #7b7b93;
  font-size: 12px;
}

.jobPara {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #7b7b93;
}

.viewBlue {
  color: #3d3bee;
  font-size: 23px !important;
}

.scroll-company {
  height: 200px;
  overflow-y: auto;
}

.img_fix {
  object-fit: cover;
  width: 100%;
  border-radius: 50%;
}

.transport_img {
  border-radius: 50%;
  width: 100%;
  height: 200px;
}

.t-scroll {
  height: 57vh;
  overflow-y: auto;
}

.serviceImage {
  height: 179px;
}

.serviceImage img {
  width: 100%;
  height: 100%;
}

.portfolioImg {
  height: 136px;
  margin: 0px 4px;
}

.portfolioImg img {
  width: 100%;
  height: 100%;
}

.ShopsCards {
  width: 11rem;
}

.ShopsCards_2 {
  width: 11rem;
}

.ViewlabelText {
  font-size: 14px;
  color: #7b7b93;
  font-weight: 500;
}

.w-img {
  width: 22px;
}

.w-img-upload {
  width: 27px;
}

.border-radius-btns {
  border-radius: 5px;
}

.custom_ps-4 {
  padding-left: 1.8rem;
}

.border-upload {
  width: 108px;
  border: 1px solid rgba(33, 33, 33, 0.18);
  border-radius: 6px;
  height: 80px;
}

.View-Model-Shadow {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 10px 3px #e7e8fb;
  border-radius: 6px;
  background-color: #f5f5f5;
  height: 98%;
}

.border_3 {
  border: 1px solid silver;
  background-color: rgba(217, 235, 235, 0.308);
}

.shops_buying_h {
  overflow-y: scroll;
  height: 350px;
}

.cursor_P {
  cursor: pointer;
}

/* Customize the scrollbar */
.noscroll::-webkit-scrollbar {
  width: 6px;
  /* width of the scrollbar */
  height: 5px;
  /* height of the scrollbar */
}

/* Track */
.noscroll::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* color of the scrollbar track */
}

/* Handle */
.noscroll::-webkit-scrollbar-thumb {
  background-color: #888;
  /* color of the scrollbar handle */
  border-radius: 5px;
  /* roundness of the scrollbar handle */
}

/* Handle on hover */
.noscroll::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* color of the scrollbar handle on hover */
}

.noscroll {
  overflow-x: hidden;
}

.form-check-label {
  font-size: 11px;
  font-weight: 500;
}

.left-arrow-w {
  width: 80px;
}

.icon-font-small {
  font-size: 12px !important;
}


.scroll-container {
  cursor: pointer;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Keep the content in a single line */
  width: 100%;
}

.btn-position-absolute {
  position: absolute;
  top: 0;
  right: 0;
}

/* Optional styling for the scrollbar */
.scroll-container::-webkit-scrollbar {
  width: 40px;
  height: 5px;
}

.report-icn-food {
  font-size: 16px !important;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.accordion-item {
  border: none !important;
}

@media only screen and (max-width: 1400px) {
  .labelText {
    font-size: 12px !important;
  }
}

.comapnyselectdiv {
  position: relative;
}

.comapnyselectdiv:after {
  content: "\f078";
  font: normal normal normal 17px/1 FontAwesome;
  right: 0px !important;
  top: 32px !important;
  padding: 15px 13px 12px 13px;
  position: absolute;
  pointer-events: none;
  background-color: #0353a4;
  border-radius: 6px;
  color: white;
}

.placeholderClorset::placeholder {
  color: black;
}

.groundset.nav-tabs .nav-link {
  background-color: #0353a4 !important;
  color: white !important;
  border-radius: 1px;
}

.groundset.nav-tabs .nav-link.active {
  background-color: transparent !important;
  color: #0353a4 !important;
  outline: auto;
  outline-color: #b9aaaa8a;
}

.sset {
  width: 6% !important;
  padding: 4px !important;
  height: 30px !important;
  border-radius: 4px !important;
}

.adddmoree #controlled-tab-example-tab-AddMore {
  background-color: #0353a4;
  color: white;
  border-radius: 1px;
  margin-left: 322px !important;
}

@media (width >=992px) and (width < 1200px) {
  .adddmoree #controlled-tab-example-tab-AddMore {
    margin-left: 119px !important;
  }
}

@media (width >=1200px) and (width <=1399px) {
  .adddmoree #controlled-tab-example-tab-AddMore {
    margin-left: 233px !important;
  }
}

@media (width=1400px) {
  .adddmoree #controlled-tab-example-tab-AddMore {
    margin-left: 320px !important;
  }
}

.userdash .nav-tabs .nav-link {
  outline: auto;
  outline-color: #ccccccc2;
}

.dashboardiv {
  width: 100%;
  height: 57px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dashboardimg {
  width: 55%;
  border-radius: 50%;
  /* height: 32px; */
  object-fit: cover;
}

.leftcomheighttt {
  height: 92vh !important;
}

.width10 {
  width: 10%;
}

.clrhead1 {
  background-color: #0353a4 !important;
}

.iiiinputmdl {
  width: 15%;
  height: 29px;
}

.profilebset {
  padding: 2px 4px 3px 4px;
}

.x-smallsize {
  font-size: x-small;
}

@media only screen and (max-width:399px) {
  .btn-clr-plus {
    font-size: 10px;
    width: 90px;
  }
}

.dashboard-scroll {
  overflow-y: auto;
  height: 80vh;
}

body::-webkit-scrollbar {
  display: none !important;
}

.sponsored_by {
  background-color: #0353A4;
}

.sponsored_by h3 {
  color: white;
  padding: 10px;
}

.sponsdata_img {
  width: 65%;

}

.sponsersettingrelative {
  position: relative;
}

.sponsersettingabsolute,
.sponsersettingabsolutelogin {
  position: absolute !important;
  top: 90%;
  right: 0px;
  left: 10px;
}

@media (width <=490) {
  .sponsersettingabsolute {
    position: sticky !important;
    margin-top: 130px !important;
  }
}

/* /////////////////small screeen////// */
@media (width <=575px) {
  .sponsored_by h3 {
    text-align: center;
  }

  .marqueecol {
    margin-top: 20px;
  }

  .Alldashboardfont {
    margin: 0px;
  }
}

@media (width <=767px) {

  .sponsersettingabsolute {
    position: sticky !important;
    margin-top: 161px !important;
  }

  .sponsersettingabsolutelogin {
    top: 64%;
  }

  .dashboard-scroll {
    padding-right: 6px;
  }

}

@media (width>=992px) and (width<1116px) {


  .sellingpara {
    font-size: 9px !important;
  }
}

@media (width >=320px) and (width <=360px) {

  .createbtt {
    font-size: 9px;
  }
}

@media (width >=768px) and (width <=991px) {

  .hei-adj .MuiSvgIcon-root,
  .MuiBadge-badge {
    font-size: 15px !important;
  }

  .res-manage-btn {
    font-size: 16px;
  }

  .createbtt {
    font-size: 11px;
  }

}

@media (width >=821px) and (width <886px) {
  .hei-adj {
    top: -6px !important;
    right: 16px !important;
  }

}

@media (width >=767px) and (width <=820px) {
  .hei-adj {
    top: -9px !important;
    right: 11px !important;
  }

}

@media (width <=433px) {

  .user_sett h9 {
    font-size: 9px;
  }

}

@media only screen and (max-width:575px) {
  .res-manage-btn {
    font-size: 12px;
  }

  .viewshop {
    padding: 2px;
  }

  .ShopsCards_business {
    width: 100%;
  }

  .img_hover_box {
    padding: .5rem;
  }

  .custom_ps-4 {
    padding: 0px;
  }

}

@media (width >=576px) and (width <=768px) {

  .ShopsCards_business {
    width: 16rem;
  }

}

@media only screen and (min-width:768px) and (max-width:991px) {
  .viewshop {
    padding: 2px;
    font-size: 11px;

  }

  .ShopsCards_business {
    width: 15rem;
  }

  .font-lg {
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .scroll-shops-business {
    padding: 1.7rem;
  }
}

@media screen and (max-width: 991px) {

  #uncontrolled-tab-example-tab-post,
  .adddmoree #controlled-tab-example-tab-AddMore {
    margin: 0px !important;
  }
}


@media only screen and (max-width: 492px) {
  .btns-applicationss {
    font-size: 9px;
    padding: 0px 2px 0px 2px;
  }

  .btn-application {
    font-size: 9px;
    padding: 0px 0px 0px 2px;
    display: -webkit-box;
  }

  .btn-application-red {
    font-size: 9px;
    padding: 0px 2px 0px 2px;
  }

  .btn-applications {
    font-size: 9px;
    padding: 0px 13px 0px 13px;
  }

  .btn-application .MuiSvgIcon-root,
  .btn-application-red .MuiSvgIcon-root {
    display: none;
  }

  .css-ryrseu-MuiRating-root {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 515px) {

  .update_buttn,
  .addmore_buttn,
  .delete_buttn,
  .dashboard_buttn {
    width: auto;
    font-size: 9px;
    padding: 2px 10px 2px 10px;
  }

  .update_buttn .MuiSvgIcon-root,
  .delete_buttn .MuiSvgIcon-root,
  .addmore_buttn .MuiSvgIcon-root {
    display: none;
  }

  .suggestion-div .marginset,
  .marginset {
    font-size: 11px;
  }

  .marginset .MuiSvgIcon-root {
    font-size: 13px;
  }

  .groundset .nav-tabs .nav-link {
    font-size: 11px !important;
  }
}

.eyeset {
  flex-wrap: nowrap !important;
}

@media (width >=1200px) and (width <=1399px) {
  .findjobsetbtn {
    font-size: 11px !important;
  }
}

@media (width >=992px) and (width <=1199px) {
  .findjobsetbtn {
    font-size: 10px !important;
  }
}

@media (width >=768px) and (width <=991px) {
  .mangeyourprofilee {
    font-size: 18px;

  }
}

@media (width >=320px) and (width <=404px) {
  .shopmanagebuttonsetting {
    font-size: 9px;

  }
}

.paymentmdl {
  border-radius: 4px;
  border: 1.5px solid rgba(172, 172, 172, 1);
  padding: 4px;
}

.custom-styling-card{
  border: 1px solid rgba(73, 70, 70, 0.288) !important;
}