.view-btn {
  color: white;
  padding: 1px 21px !important;
  font-size: 12px !important;
  border: 1px solid;
  border-radius: 11px;
  cursor: pointer;
  background-color: #3d3bee;
}

.post_margin {
  margin-left: 4rem !important;
}

.view-btnone {
  color: #030303;
  padding: 1px 21px !important;
  font-size: 12px !important;
  border: 1px solid;
  border-radius: 11px !important;
  cursor: pointer;
}

.delete-btn {
  color: #ee3b3b;
  padding: 1px 21px;
  font-size: 12px;
  border: 1px solid;
  border-radius: 11px;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: #ee3b3b;
  color: white;
}

.modal_txt {
  font-size: 16px;
}

.items_scroll {
  height: 53vh;
  overflow-y: auto;
}

.fix-img {
  width: 100%;
  height: 128px;
  object-fit: cover;
}

.fix-img .c_img {
  width: 100%;
  height: 130px;
  object-fit: cover;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .shop-profile-img {
    height: 130px !important;
  }

  .modal_txt {
    font-size: 14px !important;
  }

  /* .delete-btn {
    padding: 1px 13px;
    font-size: 11px;
    margin: 0px 2px;
  } */
  .view-btn {
    padding: 1px 12px;
    font-size: 11px;
    margin: 0px 5px;
  }
}

#uncontrolled-tab-example-tab-tab1 {
  background-color: #3d3bee !important;
  color: white !important;
  padding: 2px 10px !important;
  border-radius: 10px !important;
  font-size: 13px !important;
  margin-top: 0.5rem !important;
}

#uncontrolled-tab-example-tab-tab2 {
  background-color: #3d3bee !important;
  color: white !important;
  padding: 2px 10px !important;
  border-radius: 10px !important;
  font-size: 13px !important;
  margin-top: 0.5rem !important;
}

/* #uncontrolled-tab-example-tab-post {
  background-color:  #0353A4 !important;
  color: white !important;
  padding: 2px 15px !important;
  border-radius: -88px !important;
  font-size: 13px !important;
  margin-top: 0.5rem !important;
 
} */
/* #uncontrolled-tab-example-tab-sales {
    background-color:  #0353A4 !important;
    color: white !important;
}
#uncontrolled-tab-example-tab-special{
    background-color:  #0353A4 !important;
    color: white !important;
}
#uncontrolled-tab-example-tab-jobs{
    background-color:  #0353A4 !important;
    color: white !important;
} */

#uncontrolled-tab-example-tab-post {
  margin-left: 5rem;
  background-color: #0353a4 !important;
  color: white;
}

#uncontrolled-tab-example-tab-post .active {
  color: white !important;
  background-color: #0353a4 !important;
}

.tabsajdust .nav-item .nav-link {
  padding: 5px;
  background-color: #0353a4 !important;
  color: white;
  border-radius: 0%;
}

.nav-tabs .nav-link.active {
  color: #0353a4 !important;
  background-color: transparent !important;
}

.nav-item {
  margin-left: 2px;
}