.contained-b {
  /* border-bottom: 1px solid #c4c4c4; */
  border-top: 1px solid;
}

.classset-hr {
  height: 60vh;
  overflow-y: scroll;
 
}

.curor-poin {
  cursor: pointer;
}


.borderone_2{
    border: 3px solid rgba(0, 0, 0, 0.2) !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    min-height: 240px;
}

.manage-shopone{
  color: #0353A4;
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 29px;
}

