.Order-Shadow {
  background: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 10px 3px #e7e8fb;
  /* height: 330px; */
  height: 480px;
  overflow-y: auto;
}
.mx-height-order {
  max-height: 380px;
}
.btn-status {
  padding: 0px 8px;
  color: #ee3b3b;
  border: 1px solid;

  border-radius: 10px;
  text-align: center;
  width: 60%;
}

.btn-statusone {
  padding: 0px 8px;
  color: green;
  border: 1px solid;
  width: 60%;
  border-radius: 10px;
  text-align: center;
}
.order_setting {
  margin-left: 12%;
}
.btn-statustwo {
    padding: 0px 8px;
    color: #FAAF3A;
    border: 1px solid;
    width: 60%;
    border-radius: 10px;
    text-align: center;
  }
  .pointer_img{
    cursor: pointer;
  }
