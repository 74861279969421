/* .back-clr {
  width: 30px;
  height: 30px;

  background: rgba(0, 0, 0, 0.5);
  color: white!important;
} */

.back-clred {
  width: 31px !important;
  height: 26px !important;
  background: rgba(0, 0, 0, 0.5);
  color: white !important;
  margin-left: 8px;
}

.back-off {
  width: 31px !important;
  height: 26px !important;
  background: rgba(214, 40, 40, 0.8);
  color: white;
  margin-left: 8px;
}

.intor-on {
  width: 55px;
  height: 23px;
  left: 796px;
  top: 272px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 33px;
  font-size: 12px;
  margin-left: 38px;
  color: white;
  font-weight: 400;
  margin-top: 6px;
}

.btr-om {
  top: -35px;
}

.card-dld {
  width: 100%;
}

@media (max-width: 1232px) and (min-width: 992px) {
  .card-dld {
    width: 8rem;
  }
  .back-clred {
    width: 18px !important;
    height: 24px !important;
    background: rgba(0, 0, 0, 0.5);
    color: white !important;
    margin-left: 5px;
  }

  .back-off {
    width: 18px !important;
    height: 24px !important;
  }

  .intor-on {
    width: 45px;
    height: 23px;
    left: 796px;
    top: 272px;

    background: rgba(0, 0, 0, 0.5);
    border-radius: 33px;
    font-size: 11px;
    margin-left: 47px;
    color: white;
    font-weight: 400;
    margin-top: 6px;
  }
}

@media (max-width: 1023px) and (min-width: 992px) {
  .card-dld {
    width: 7rem;
  }

  .back-clred {
    width: 18px !important;
    height: 24px !important;
    background: rgba(0, 0, 0, 0.5);
    color: white !important;
    margin-left: 5px;
    margin-top: 6px;
  }

  .back-off {
    width: 18px !important;
    height: 24px !important;
    margin-top: 6px;
  }

  .intor-on {
    width: 39px;
    height: 23px;

    font-size: 11px;
    margin-left: 38px;

  }
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .intor-on {
    width: 37px;
    height: 23px;
    left: 796px;
    top: 272px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 33px;
    font-size: 10px;
    margin-left: 49px;
    color: white;
    font-weight: 400;
    margin-top: 6px;
}

.back-clred {
  width: 18px !important;
  height: 24px !important;
  background: rgba(0, 0, 0, 0.5);
  color: white !important;
  margin-left: 5px;
  margin-top: 6px;
}

.back-off {
  width: 18px !important;
  height: 24px !important;
  margin-top: 6px;
}
}

@media (max-width: 1199px) and (min-width: 1024px) {
  .card-dld {
    width: 7rem;
  }
  .intor-on{
    margin-left: 27px;
  }
  .back-off {
    
    margin-top: 6px;
  }
  .back-clred {
   
    margin-top: 6px;
  }
}


@media (max-width: 991px) and (min-width: 768px) { 
  .card-dld {
    width: 7rem;
  }

  .back-clred {
    width: 18px !important;
    height: 24px !important;
    margin-left: 5px;
    margin-top: 4px;
  }
  
}
