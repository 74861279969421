.scroll-company-jobs {
    height: 365px;
    overflow-y: auto;
}

.drop-abb{
    background-color: #f8f8fd;
    width: 100%;
    overflow-y: auto;
    height: 150px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    border-top: none;
  }