
/* search icon */
.searchContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%; /* Adjust as per your requirement */
}

.searchInput {
  width: 100%;
  padding: 10px;
  padding-right: 40px; /* Add padding to make space for the icon */
  font-size: 16px;
}

.searchIcon {
  position: absolute;
  right: 10px; /* Position the icon to the right inside the input */
  color: #666;
  pointer-events: none; /* Disable pointer events to avoid interfering with input */
}



.modalOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
  }
  
  .modalDialog {
    max-width: 1200px; /* Adjust max width */
    width: 100%;
    border-radius: 10px;
    overflow: hidden; /* Ensure contents fit */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }



  .header {
    font-size: 2rem;
    padding: 20px;
    display: flex; /* Use flex to align items */
    justify-content: space-between; /* Space between header text and buttons */
    align-items: center; /* Center items vertically */
  background-color: white;
  }

  
  .header h2 {
    margin: 0;
    font-size: 1.5rem; /* Adjust size as needed */
  }
  

 .closeButton { 
  background: none; /* No background color */
  border: none; /* No border */
  color: red; /* Red color for the close button */
  font-size: 2.0rem; /* Adjust size for the close icon */
  cursor: pointer;

}

.closeButton:hover {
  color: darkred; /* Optional hover effect */
}

.maximizeButton {
  background: none; /* No background color */
  border: none; /* No border */
  color: blue; /* Color for the maximizer button */ 
  cursor: pointer;
  margin-left: auto; /* Add 10px space between maximizer and close button */
}

.maximizeButton:hover {
  color: darkblue; /* Optional hover effect for maximizer */
}

  .modalHeader {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .headerIcons {
    display: flex;
    gap: 10px; /* Space between icons */
  }
  
  .iconBlack {
    color: black;
  }
  
  .iconRed {
    color: red;
  }
  
  .modalBody {
    display: flex;
    height: 600px; /* Adjust height as necessary */
  }
  
  .leftSection {
    width: 25%;
    background-color: #e9ecef;
    border-right: 1px solid #dee2e6;
    padding: 10px;
    overflow-y: auto; /* Scroll if content overflows */
  }
  
  .searchContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    
  }
  
  .searchInput {
    flex: 1;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 40px;
  }
  
  .searchIcon {
    margin-left: 5px;
  }
  








  .chatList {
    max-height: auto; /* Adjust max height */
}

.chatItem {
    padding: 5px 0; /* Reduce padding for chat items */
    cursor: pointer;
    max-height: auto; /* Set a maximum height to control the overall height of each chat item */
}

.chatInfo {
    display: flex;
    align-items: center; /* Vertically center the image and text */
}

.leftprofileImage {
    width: 30px; /* Smaller circular image */
    height: 30px; /* Ensure height equals width */
    border-radius: 50%; /* Make the image circular */
    object-fit: cover; /* Ensure the image maintains aspect ratio */
    margin-right: 10px;
}

.chatMessage {
    font-size: 11px; /* Smaller font for the message */
    color: gray;
    margin-top: 2px; /* Reduce spacing between message and username */
    margin-bottom: 0; /* Remove extra bottom margin */
}

.chatInfo div {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center the text inside the div */
}


  




/* center section */

.personInfo {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensures vertical centering */
  background-color: #ffffff;
  padding: 10px;
}


.messageFieldInput{
  margin: 10px;
}

.userprofileImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.personDetails {
  margin-left: 10px; /* Space between image and text */
}

.personDetails h6, .personDetails p {
  margin: 0; /* Remove any default margin */
}

.rightInfo {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the text vertically */
  gap: 1px; /* Adjust space between paragraphs */
}

.rightInfo p {
  margin: 0;
}

/* bottom center */
  .centerSection {
    width: 55%;
    background-color: #d9edf7;
    border: 1px solid rgb(226, 226, 226);
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Scroll if content overflows */
    justify-content: space-between; /* Push input to the bottom */
    margin: 0;
  }








  

/* center ceter area */
.messageInput {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  border-top: 1px solid #ced4da;
  background-color: #fff;
}

.inputWrapper {
  position: relative;
  width: 100%;
}

.messageFieldInput {
  width: 100%; /* Full width for the input field */
  padding: 10px 50px; /* Left padding for attachment button */
  border: 1px solid #ced4da;
  border-radius: 25px;
  box-sizing: border-box;
}

.attachmentButton {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #007bff;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  position: absolute; /* Position buttons relative to input wrapper */
  right: -80px; /* Adjust this to ensure icons are outside the input */
  top: 50%;
  transform: translateY(-50%);
}

.emojiButton {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
  color: #007bff;
  margin-right: 20px; /* Space between emoji and send icon */
}

.sendButton {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #007bff;
}

/* Responsive adjustments to ensure no overlap on smaller screens */
@media (max-width: 600px) {
  .buttonWrapper {
    right: -60px; /* Adjust position as needed for smaller screens */
  }
  .messageFieldInput {
    padding-right: 60px; /* Adjust padding for smaller screens */
  }
}










  
  .bgColor {
    background-color: #b3e5fc; /* Sky color */
    padding: 10px;
    margin: 10px 0;
  }
  

  




  

  
  .rightSection {
    width: 20%;
    background-color: #e9ecef;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10%;
    height: 100%; /* Ensures full height of the parent container */
  }

  .rightprofileImage{
      width: 67px; /* Define a fixed width for the circular image */
      height: 67px; /* Make sure height is equal to width */
      border-radius: 50%; /* Make the image circular */
      object-fit: cover; /* Ensure the image maintains aspect ratio */
      margin-bottom: 10px;
  }

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: center; /* Aligns the items to the center */
  margin-bottom: 20px;
}
.profileImage {
  width: 50px; /* Define a fixed width for the circular image */
  height: 50px; /* Make sure height is equal to width */
  border-radius: 50%; /* Make the image circular */
  object-fit: cover; /* Ensure the image maintains aspect ratio */
  margin-bottom: 10px;
}






.switchProfileContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}


  
  .userName {
    font-weight: bold;
    font-size: 16px;
    text-align: center; /* Center the text */
  }
  .userProfile {
    font-size: 14px;
    color: gray;
    text-align: center; /* Center the text */
    margin-bottom: 2px; /* Reduce margin to bring Receiver closer */
  }

  .userReciever {
    font-size: 12px;
    color: gray;
    text-align: center; /* Center the text */
    margin-top: 2px; /* Reduce space between the General Profile and Receiver */
    margin-bottom: 15px; /* Maintain spacing before select field */
  }


  .userReciever {
    font-size: 12px;
    color: gray;
    text-align: center; /* Center the text */
    margin-bottom: 15px; /* Add some space before the select field */
  }


  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-right: 10px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .selectField {
    width: 150px;
    padding: 4px;
    border: 2px solid #0353a4; /* Adds the blue border */
    border-radius: 20px; /* Rounds the borders */
    color: #0353a4; /* Sets the text color */
    text-align: center; /* Centers the text inside */
    background-color: white; /* Ensures the background is white */
  }

  .selectField option {
    color: #000; /* Ensure option text is black */
  }
  
  .selectField::placeholder {
    color: #0353a4; /* Adds color to placeholder text */
  }

  /* Center the content inside the select dropdown */
.selectField option[value="profile1"]::before {
  content: "Switch profile"; /* Placeholder for the select dropdown */
}


















/* Styles for large screens (default) */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  font-size: 2rem;
  padding: 20px;
}

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  width: 30%;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f4f4f4;
}

.text {
  font-size: 1.5rem;
}

/* Responsive for small screens */
@media (max-width: 768px) {
  .header {
    font-size: 1.5rem; /* Reduce the text size */
    padding: 15px; /* Adjust padding */
  }

  .section {
    flex-direction: column; /* Stack cards vertically */
  }

  .card {
    width: 100%; /* Full width for small screens */
    margin-bottom: 15px; /* Adjust margin */
    padding: 15px; /* Adjust padding */
  }

  .text {
    font-size: 1.2rem; /* Decrease text size */
  }
}

@media (max-width: 480px) {
  .header {
    font-size: 1.2rem; /* Smaller text size for very small screens */
    padding: 10px; /* Adjust padding */
  }

  .section {
    padding: 10px; /* Reduce section padding */
  }

  .card {
    width: 100%;
    padding: 10px; /* Adjust card padding */
  }

  .text {
    font-size: 1rem; /* Further decrease text size */
  }
}




















/* Chat bot */
.messageArea {
  overflow-y: auto;
  padding: 10px;
}

.messageBox {
  background-color: #e1f5fe; /* Light blue background for own messages */
  border-radius: 10px;
  padding: 5px; /* Reduced padding for height adjustment */
  margin: 5px 0;
  position: relative;
  max-width: 80%; /* Adjust the width */
  margin-left: auto; /* For messages sent by 'me' */
  margin-right: auto; /* For messages from 'other' */
}
.messageContent {
  display: flex;
  justify-content: space-between; /* Adjust space between text and timestamp */
  align-items: center; /* Center items vertically */

}

.myMessage {
  background-color: #00ACED; /* Blue for your message */
  color: white;
  margin-left: auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 10px;
  text-align: right;
  float: right; /* Ensures message aligns right */
  clear: both; /* Clears the floating from previous elements */
  padding: 5px; /* Reduced padding */
}

.otherMessage {
  background-color: white; /* White for the other person */
  color: black;
  margin-right: auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 0;
  text-align: left;
  float: left; /* Ensures message aligns left */
  clear: both; /* Clears the floating from previous elements */
  padding: 5px; /* Reduced padding */
}

.messageInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f8f8;
  border-top: 1px solid #e0e0e0;
}

.messageFieldInput {
  width: 85%;

  border: transparent;
}

.timestamp {
  font-size: 0.75em; /* Smaller font size */
  margin-left: 10px; /* Space between message and timestamp */
  color: #888; /* Color for timestamps */
  display: inline; /* Keep it inline with the message */
}

.messageText {
  word-wrap: break-word; /* Ensures long words break into the next line */
  word-break: break-word; /* Breaks long words if necessary */
  white-space: pre-wrap; /* Preserves whitespace and allows text to wrap */
  margin: 0; /* Optional: remove default margin for <p> */
}

/* Specific styles for timestamps */
.myMessage .timestamp {
  color: white; /* White color for own messages */
}

.otherMessage .timestamp {
  color: #888; /* Lighter color for other person's messages */
}



@media (max-width: 680px) {
  .rightSection {
    width: 0%;
    display: none;
  }
  .leftSection {
    width: 40%;
    background-color: #e9ecef;
    border-right: 1px solid #dee2e6;
    padding: 10px;
    overflow-y: auto; /* Scroll if content overflows */
  }
  .centerSection {
    width: 60%;
    background-color: #d9edf7;
    /* padding: 10px; */
    border: 1px solid rgb(226, 226, 226);
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Scroll if content overflows */
    justify-content: space-between; /* Push input to the bottom */
    margin: 0;
  }
   
}


@media (max-width: 500px){
  .userprofileImage {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }
  .rightinfop {
    font-size: 13px;
  }
  .leftinfop{
    font-size: 14px;
  }
}