.back-area-sent{
    background-color: #F2F2F2;
    font-size: 13px;
    font-weight: 600;
    padding: 4px 16px 4px 16px ;
    border-radius: 3px;
    border: 1px solid #222222B2;

}

.clss{

    background-color: transparent;
    font-size: 12px;
    height: 10vh;

}

.trms-cndition{

    background-color: #F2F2F2;
    border: 1px solid #222222B2;
}

.input-INPuts{
    background-color: none !important;
    border: none !important;
}

.input-INPuts:focus{
    background-color: none !important;
    border: none !important;
}
.back-area .input-back{
    background-color: transparent!important;
    border: none!important;
}
.input-back:focus{
    background-color: transparent !important;
    border: none !important;
    color: white;
}

.ss{
    color: white;
}
.TermsConditionss{
    width: 100% !important;
    background-color: #F2F2F2;
}
.LBL-font{
    font-size: 13px;
}