.img_container {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img_container .food_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.marginset {
  /* margin: auto; */
  margin-bottom: 0%;
}

.raja_m {
  margin-left: -4px;
}

.main-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.main-div2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.suggestion-div {
  display: flex;
  flex-direction: column;
}

.update_buttn {
  background-color: #e9a71d;
  border-radius: 6px;
  width: 102%;
  color: white;
  margin-bottom: 10px;
  padding: 5px 15px;
  border: none;
  font-size: 14px;
}

.addmore_buttn {
  background-color: #3d3bee;
  border-radius: 6px;
  width: 100%;
  color: white;
  padding: 4px 10px;
  border: none;
  font-size: 14px;
}

.delete_buttn {
  background-color: #d62828;
  border-radius: 6px;
  width: 97%;
  color: white;
  margin-bottom: 12px;
  padding: 5px 16px;
  border: none;
  font-size: 14px;
}

.dashboard_buttn {
  background-color: #3d3bee;
  border-radius: 6px;
  width: 100%;
  color: white;
  padding: 5px 7px;
  border: none;
  font-size: 14px;
}

.sharelctnbtn {
  border-radius: 6px;
  /* width: 100%; */
  /* color: white; */
  background-color: transparent;
  border-color: #0353a4;
}

.live_lcc {
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
  margin-bottom: -44px;
  /* right: 20%; */
  /* top: 45%; */
}

.img_fixxx {
  height: 243px;
  object-fit: cover;
  border-radius: 50%;
  width: 100%;
}

.transport_imgggg {
  height: 245px;
  margin-left: 25px;
  border-radius: 50%;
}

.main_rowset {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.backarrowSet {
  margin-left: -37px;
}

@media (width >=600px) and (width <=991px) {
  .small_screenset {
    margin-left: 100px;
  }

  .transport_imgggg {
    width: 40% !important;
  }
}

@media (width >=992px) and (width <=1199px) {
  .main_rowset {
    display: flex;
    justify-content: space-between;
  }

  .raja_divset {
    margin-left: -33px;
  }

  .suggestion-div {
    margin-left: -36px;
  }

  .img_fixxx {
    height: 171px;
  }

  .transport_imgggg {
    height: 176px;
    margin-left: -15px;
  }

  .update_buttn {
    width: 100%;
  }

  .addmore_buttn {
    width: 100%;
  }

  /* .delete_buttn {
  }
  .dashboard_buttn {
  } */
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .transport_imgggg {
    height: 221px;
    margin-left: 0px;
  }

  .img_fixxx {
    height: 219px;
  }
}

@media (width >=1400px) {
  .transport_imgggg {
    margin-left: 0px;
  }
}