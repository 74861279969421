.commentsBackground {
    border-radius: 8px;
    height: auto;
    overflow: hidden;
    background-color: #267DCF;
}

.scrollingComments {
    overflow-y: auto;
    overflow-x: hidden;
    height:465px ;
}

.search .fa-grin {

    position: absolute;
    top: 12px;
    left: 16px;

}


.search  {
    border-radius: 20px;
    height: 36px;
}

.sendButton {
    background: #3D3BEE;
    border-radius: 20px;
    color: white;
    padding: 5px 20px;
}

.commentsImg img {
    height: 50px;
}

.commentsText {
    padding-left: 10px;
    padding-top: 6px;
   

  
}



.commentsText h6 {
    color: white;
    font-size: 14px;
}

.commentsText p {
    color: white;
    font-size: 12px;
    padding-top: 4px;
    max-width: 266px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}