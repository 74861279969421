.Juli-para{

    font-size: 13px;
    color: #7B7B93;

}

.Juli-date{

    font-size: 12px;
    color:#7B7B93 ;
    font-weight: 500;

}

.Juli-font-name{

    font-weight: 500 ;
    font-size: 14px;

}

.scroll-card-Juli{

    overflow-y: scroll;
    height: 84vh;

}

.juli-titttle{

    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px;

}

