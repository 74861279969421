.cv-header-4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #1A3316;
}
.title-margin-4{
    margin-bottom: 0px;
    color: #000000;
    font-weight: 500;
    font-size: 14px;
}
.desc-margin-4{
    margin-bottom: 6px;
    color: #7B7B93;
    font-weight: 500;
    font-size: 14px;
}