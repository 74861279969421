.custom-card{
    border:1px solid rgba(148, 148, 148, 1) !important;
    box-shadow: -3px 6px 8px 0px rgba(0, 0, 0, 0.25);
 
    border-radius: 8px;
}
.center-alignment{
    display: flex;
    align-items: center;
    height: 80vh;
}