.password-width {
  width: 95%;
}

.password-width-signup {
  width: 90%;
}

.height-login {
  transform: translate(20px, 70px);
}

.left-side,
.right-side {
  height: 100vh;
}

.loginsetheight {
  height: 90vh;
}

.left-sides {
  background-image: linear-gradient(119.72deg,
      rgba(21, 117, 31, 0.9) 5.7%,
      rgb(13, 60, 130, 0.9) 93.64%),
    url("../../assets/images/dashboard/loginpics.svg");
  background-size: cover;
}

.login-button {
  background-color: #020094 !important;
}

.cur-pointer {
  cursor: pointer;
}

.search-input {
  border: none;
  outline: none;
}

.cstm-h {
  height: 730px;
  background-color: red;
}

.login_font {
  font-size: 14px;
  color: rgb(184, 182, 182);
}

.labelSize {
  font-weight: 500;
  font-size: 13px;
}

.inputSizing {
  padding: 12px 0px 12px 12px !important;
}

.blood-type {
  padding: 3px 7px 2px 5px !important;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #ced4da;
  font-size: 12px;
  color: #495057;
}

.colorless-icon {
  color: transparent;
}

.search-input::placeholder {
  font-size: 12px !important;
}

.selectSize {
  border: none;
  outline: none;
}

.location_map_btn {
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #0353a4;
  text-align: center;
  font-size: 14px;
  width: 100%;
}