.largeBoxSize{
  width: 100%;
  border: none !important;
}
.DialogBox {
  position: absolute;
  z-index: 1;
  top: -80px;
  right: 57px;
}

.Backgroundbox {
  background: #ffffff;
  box-shadow: 0px 4px 4px 3px #e7e8fb;
  border-radius: 4px;
 
}

.boxes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  border-top: none !important;
}

.select_box_W {
  width: 20% !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.pen_cls {
  margin-left: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  height: fit-content;
}


.location_buton {
  background-color: transparent;
  border-color: #0353a4;
  width: 175px;
  height: 30px;
  border-radius: 4px;
  margin-top: auto;
  margin-bottom: auto;
}


.QRandbtn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;

}

.userid_setting {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.profile_set {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 400px) and (min-width: 320px) {
  .largeBoxSize {
    width: 95% !important;
  }
}

@media (width <=575px) {
  .gb {
    justify-content: center;

  }

  .extraset p {
    font-size: 10px;
  }

  .extraset h4 {
    font-size: 17px;
  }

  .emptydiv {}

  .bb {
    margin-top: 18% !important;
    padding: 0px 0px 0px 12px;
  }

  .extrauseridset b {
    font-size: 12px;

  }

  .extrauseridset {
    font-size: 12px;

  }
}

@media (width <=412px) {
  .bb {
    margin-top: 25% !important;
  }

  .uuswerid {
    font-size: 10px !important;
  }
}

@media (width <=369px) {
  .extrauseridset {
    margin-left: 22px;
  }
}