.img_four-div{
    width: 80px!important;
    height: 6rem!important;
}
.phera-faq{
    font-weight: 500;
}
.cht-online{
    font-weight: 500;
}
.phr{
    font-size: 13px;
}
.fsda{
    font-size: 1px;
}