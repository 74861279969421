/* .animation {
    text-align: center;
    margin: 20px;
  }
  
  .tick {
    font-size: 48px;
    color: green;
    animation: tickAnimation 1s infinite;
  }
  
  @keyframes tickAnimation {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  } */

  .animation {
  text-align: center;
  margin: 20px;
}

.tick-container {
  display: inline-block;
  position: relative;
}

.tick {
  font-size: 48px;
  color: white;
  border-radius: 50%;
  background-color: green;
  padding: 10px;
  opacity: 0;
  animation: tickAnimation 1s ease-in-out 0.5s forwards;
}

@keyframes tickAnimation {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}