@media (max-width: 855px) and (min-width: 692px) {
    .custm-lble-2 {
        color: #212121;
        font-weight: 600 !important;
        font-size: 11px !important;
    }

    .createHeading {
        font-size: 16px !important;
    }

    /* .w-img {
        width: 65px !important;
    } */
}

@media (max-width: 691px) and (min-width: 592px) {
    .custm-lble-2 {
        color: #212121;

        font-size: 13px !important;
    }

    .createHeading {
        font-size: 13px !important;
    }

    .w-img {
        width: 50px !important;
    }
}

/* @media (max-width: 511px) and (min-width: 435px) {
    .w-img {
        width: 65px !important;
    }
} */

@media (max-width: 591px) and (min-width: 435px) {
    .custm-lble-2 {
        color: #212121;

        font-size: 13px !important;
    }

    .createHeading {
        font-size: 14px !important;
    }

    /* .w-img {
        width: 60px !important;
    } */
}

@media (max-width: 434px) and (min-width: 320px) {
    .custm-lble-2 {
        color: #212121;

        font-size: 13px !important;
    }

    .createHeading {
        font-size: 12px !important;
    }

    /* .w-img {
        width: 50px !important;
    } */
}