.btn-application-red {
    background: #EE3B3B;
    color: white;
    font-size: 14px;
    font-weight: 400;
    border: transparent;
    border-radius: 5px;
    padding: 3px 17px;
}
.btns-connectss {
    background-color: #0353A4!important;
    color: white !important;
    width: 12vh !important;
    border: transparent !important;
    border-radius: 7px;
    height: 5vh;
}
.report-icn{
    color: rgb(29, 29, 34);
    font-size: 20px !important;
    cursor: pointer;
    margin-top: .7%!important;
}
#controlled-tab-example-tab-tab1
{background: #3d3bee;
    color: white !important;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px !important;
}

.location-live{
    background-color: white !important;
    border:2px solid #0353A4 !important;
    color:#0353A4 !important;
    font-weight: 600;
    border-radius: 6px;
    font-size: 13px;
    
}
.live-btn-absolute{
    position: absolute;
    right:0;
}
.custom-negative-m{
    margin-top: -2%;
}
