.side_div {
    background: #267dcf !important;
    border-radius: 8px;
}

.flex-Section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.flex-Section h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
}

.para-3 {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.10em;
}

.para-4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 122% */
    text-align: center;
    letter-spacing: 0.04em;
}

.Buttons {
    background: #212121;
    border-radius: 5px;
    font-size: 13px;
}

.search_bar {
    background: #267dcf !important;
    border-radius: 8px;

}


.tns_Section_Starts  p {
    font-weight: 400;
    font-size: 13px;
}

.img-para-flex {
    display: flex;
    align-items: center;

}

.img-para-flex 
h4 {
    text-align: start;
    padding: 0px 0px 0px 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px
}

.lablee {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
}

.select-box {
    background: #023535;
    border-radius: 5px;
    border: 1px solid white;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #C4C4C4;
}

.new_buttons {
    display: flex;

 
}

.new_buttons    p {
    border: 1px solid white !important;
    color: #C4C4C4;
    font-weight: 300;
    font-size: 12px;
    padding: 3px 8px 3px 8px
}

.btn-minis {
    background: white !important;
    padding: 0px 10px 0px 10px !important;
    height: 26px !important;
    color:#212121 !important;
    border: 1px solid white;
}

.btn-plus {
    background: #212121!important;
    border-radius: 0px 6px 6px 0px !important;
    padding: 0px 10px 0px 10px !important;
    color:white;
    height: 27px !important;
    border: 1px solid #212121;
}

.Btn_radius{
    background: #212121!important;
}

.img-width_chat {
    width: 38px;
}

.profilepic {
    width: 60%;
}

.create-btn {
    width: 100%;
    font-size: 14px;
}
.heightmatch{
    margin-bottom: 4px;
}