.food-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.services-scroll-card {
    height: 61vh;
    overflow-y: auto;
  }

.resturent-height{
  height: 33vh;
  overflow-y: auto;
}

  .noscroll-one{
overflow-x: hidden;
width: 100%;
  }
  #controlled-tab-example-tab-Postitems{
    background:#0353A4 ;
    color:white;
    margin-left: 28.4rem;
  }
  .sales{
    position: absolute;
    background-color: #dc3545;
    color: white;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
  }

  .salestwo{
    position: absolute;
    background-color: #dc3545;
    color: white;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
  }



  /* .image-setting-one{
    width: 50%;
    height: 30vh;
  } */

  /* .image-width-set{
    width: 80%;
  } */


 