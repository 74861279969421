.email-class{

    border: 1px solid rgba(51, 51, 51, 0.5);

}

.mainchats-head-class{

    border: 1px solid rgba(51, 51, 51, 0.5);

}