.liveOwnChatRoomBackground {
    background: black;
    border-radius: 8px;
    height:auto;

}
.liveChatRoomBackground{
    background: black;
    border-radius: 8px;
    margin: 20px 0px;
    height: auto;
}
.ownChatroomImg {
   width:100%;
   height:500px;
}





.microphoneBackground {
    background: #E7E8FB;
    border-radius: 50px;
    padding: 8px 10px; 
    
}

.microphone{
    color:purple !important;
    font-size: 30px !important;
}

.liveClose {
    background: #EE3B3B;
    border-radius: 6px;
    color: white;
    padding: 4px 20px;
}

.inviteChatroomButton {
    background: #3D3BEE;
    border-radius: 6px;
    display: flex;
    padding: 4px 20px; 
}

.fa-user-plus {
    color: white;
    font-size: 13px;
}

.inviteChatroomButton p {
    color: white;
}
.chatTime p{
    color: white;
    font-size: 20px;
}
.iconsBackgroundVoice{
    background: #E7E8FB;
    border-radius: 50px;
    padding: 12px 15px;
    color: #3641B7;
    font-size: 20px;
}
.iconsBackgroundVideo{
    background: #E7E8FB;
    border-radius: 50px;
    padding: 12px 15px;
    color: #0FC2C0;
    font-size: 18px;
}