.para-height {
  height: 70vh;
}

.text-right img {

  width: 25px;

}

.para-left-style {
  color: white;
  font-size: 1.1rem;

}

.selectezilogo {
  /* width: 40%; */
  color: #003559;
  font-weight: 700;
}