.scrollable {
    height: 48vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
}

.card_W {
    width: 45% !important;
}

@media only screen and (min-width:1200px) and (max-width:1399px) {

    .h6_respons {
        font-size: 13px !important;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {

    .view_profileset {
        font-size: 10px !important;
    }

}