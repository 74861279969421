.two-hands-img{

    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 32vh;

}



.classs{

    height: 71vh;

}