body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#uncontrolled-tab-example-tab-Items{
  margin-right: 440px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea:focus, input:focus{
  outline: none !important;
  box-shadow: none !important;
  
}
.search-icon-fs{
  font-size: 18px !important;
}
input::placeholder{
  font-size: 13px;
}
a{
  text-decoration: none !important;
}
a:hover{
  text-decoration: underline !important;
}
.font-bold{
  font-weight: 700 !important;
}
.b-radius{
border-radius: 6px !important;
}


.area_br_none{
  outline: none !important;
  box-shadow: none !important;
  border: none!important;
}

/* Customize the scrollbar */
.noscroll::-webkit-scrollbar {
  width: 6px; /* width of the scrollbar */
  height: 5px; /* height of the scrollbar */
}


.Toastify__toast-container{
  width: 25% !important;
}



