.paywith-div {
  color: black;
  font-weight: 600;
  font-size: 18px;
}

.ratiobtn-div {
  color: black;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}

.verify-input {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-width: 5px 0px 5px 0px;
  padding: 5px !important;
}


/* body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    background-color: #f0f0f0;
  }
   */
.otp-container {
  display: flex;
  gap: 1rem;
}

.otp-input {
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Add styling for when input is focused */
.otp-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.button-settingdone {
  padding: 7px 148px 11px 124px;
  border-radius: 4px;
  gap: 10px;
  background-color: rgba(0, 109, 170, 1);
  text-decoration-color: aliceblue;
  color: green;

}

.button-settingdone:hover {
  background-color: rgba(47, 189, 106, 1);
}

.textcolor-set {
  color: rgba(172, 172, 172, 1);
  font-size: 14px;
  padding: 8px;
}

.includecolor-set {
  color: rgba(172, 172, 172, 1);
  font-size: 14px;
}

@media (width <=420px) {
  .button-settingdone {
    padding: 4px 0px 4px 0px;
  }
}

@media (width <=448px) {
  .confirmpaymentsett {
    padding: 5px 0px 5px 0px !important;
  }

  .fourdigits {
    font-size: small !important;
  }
}