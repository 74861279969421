.shadow-main {
  background: #FAFAFA;
  border: 1px solid #A8A8A8;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 18px 18px;
  border-radius: 20px;
}

@media (max-width: 575px) and (min-width: 320px) {
  .shadow-main {
    margin-top: 10px;
  }
  .shadow-joboprt{
    margin-top: 10px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {

  .set-one {
    margin-top: 10px;
  }

}

@media (max-width: 767px) and (min-width: 685px) {
  .set-one {
    margin-top: 10px;
  }


}