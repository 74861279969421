.miniProfile {
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    height: 100%;
    padding: 10px 0px 0px 2px;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.Profile-img-mini {
    width: 102px;
    height: 102px;
}

.dragImg {
    cursor: pointer;
}

.Profile-img-mini img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: fill;
}

.btn-primary-mini {
    background-color: #0353a4;
    color: white;
    border: transparent;
    font-size: 13px;
    padding: 7px 0px;
}



@media (max-width: 1600px) and (min-width: 1400px) {
    .font_small {
        font-size: 12px !important;
    }

    .display-setting-2 {

        width: 1023px !important;

    }
}

@media (max-width: 1399px) and (min-width: 1200px) {
    .font_small {
        font-size: 12px !important;
    }

    .display-setting-2 {

        width: 950px !important;

    }

    .privacyText {
        font-size: 11px !important;
        padding-top: 6px !important;
    }


}

@media (max-width: 1199px) and (min-width: 992px) {
    .font_small {
        font-size: 9px !important;
    }

    .chatboxImg2 {
        height: 33px !important;
    }

    .icon_f_sm {
        font-size: 11px !important;
    }

    .h6_respons {
        font-size: 12px !important;
    }

    .privacyText {
        font-size: 12px !important;
        padding-top: 6px !important;
    }

    .display-setting {
        width: 339px !important;
    }

    .personImgSize img {
        height: 45px !important;
        width: 45px !important;
    }

    .folow-btn {
        font-size: 8px !important;
    }

    .Profile-img-mini {
        width: 73px !important;
        height: 77px !important;
    }

    .btn-primary {
        font-size: 8px !important;
    }

    .lessWidthData {
        overflow-x: auto;
    }

    .btn-primary {
        font-size: 10px !important;
    }

    .btn-primary-mini {
        padding: 7px 11px !important;

    }

    .shopnamesetting {
        font-size: 10px !important;
    }

}

@media (max-width: 991px) and (min-width: 768px) {
    .dragImg img {
        display: none !important;
    }

    .Coverimage_dp {
        left: 64% !important;
    }

    .smallWidth {
        width: 31% !important;
    }

    .screenHeight {
        height: unset !important;
        margin-top: 59px !important;
    }

    .display-setting {
        width: 633px !important;
    }

    .timelinescroll {
        padding: 0px 30px !important;
    }
}

@media (max-width: 767px) and (min-width: 530px) {
    .dragImg img {
        display: none !important;
    }

    .Coverimage_dp {
        left: 64% !important;
    }

    .smallWidth {
        width: 31% !important;
    }

    .screenHeight {
        height: unset !important;
        margin-top: 59px !important;
    }

    .display-setting {
        width: 467px !important;
    }

    .timelinescroll {
        padding: 0px 30px !important;
    }

    .font_small {
        font-size: 10px !important;
    }

    .smallDialogBox {
        position: unset !important;
    }

}

@media (max-width: 529px) and (min-width: 496px) {
    .dragImg img {
        display: none !important;
    }

    .Coverimage_dp {
        left: 64% !important;
    }

    .smallWidth {
        width: 31% !important;
    }

    .screenHeight {
        height: unset !important;
        margin-top: 59px !important;
    }

    .display-setting {
        width: 425px !important;
    }

    .timelinescroll {
        padding: 0px 30px !important;
    }

    .font_small {
        font-size: 10px !important;
    }

    .smallDialogBox {
        position: unset !important;
    }

}

@media (max-width: 495px) and (min-width: 458px) {
    .dragImg img {
        display: none !important;
    }

    .Coverimage_dp {
        left: 64% !important;
    }

    .smallWidth {
        width: 31% !important;
    }

    .screenHeight {
        height: unset !important;
        margin-top: 59px !important;
    }

    .display-setting {
        width: 395px !important;
    }

    .timelinescroll {
        padding: 0px 30px !important;
    }

    .font_small {
        font-size: 10px !important;
    }

    .smallDialogBox {
        position: unset !important;
    }

    .h6_respons {
        font-size: 11px !important;
    }

}

@media (max-width: 457px) and (min-width: 408px) {
    .dragImg img {
        display: none !important;
    }

    .Coverimage_dp {
        left: 64% !important;
    }

    .smallWidth {
        width: 31% !important;
    }

    .screenHeight {
        height: unset !important;
        margin-top: 59px !important;
    }

    .display-setting {
        width: 349px !important;
    }

    .timelinescroll {
        padding: 0px 30px !important;
    }

    .font_small {
        font-size: 7px !important;
    }

    .smallDialogBox {
        position: unset !important;
    }

    .icon_f {
        font-size: 13px !important;
    }

    .h6_respons {
        font-size: 10px !important;
    }

    .font_exsmall {
        font-size: 9px !important;
        padding: 1px 0px 0px 2px !important;
    }

    .personImgSize img {
        height: 44px !important;
        width: 44px !important;
    }

    .folow-btn {
        padding: 0px 10px 0px 10px !important;
        font-size: 9px !important;
    }
}

@media (max-width: 407px) and (min-width: 370px) {
    .dragImg img {
        display: none !important;
    }

    .Coverimage_dp {
        left: 64% !important;
    }

    .nav-tabs .nav-link {
        font-size: 12px !important;
    }

    .smallWidth {
        width: 31% !important;
    }

    .screenHeight {
        height: unset !important;
        margin-top: 59px !important;
    }

    .display-setting {
        width: 310px !important;
    }

    .timelinescroll {
        padding: 0px 30px !important;
    }

    .font_small {
        font-size: 7px !important;
    }

    .smallDialogBox {
        position: unset !important;
    }

    .icon_f {
        font-size: 13px !important;
    }

    .h6_respons {
        font-size: 9px !important;
    }

    .font_exsmall {
        font-size: 7px !important;
        padding: 1px 0px 0px 2px !important;
    }

    .personImgSize img {
        height: 37px !important;
        width: 37px !important;
    }

    /* .folow-btn {
        padding: 0px 10px 0px 10px !important;
        font-size: 7px !important;
    } */
}

@media (max-width: 518px) and (min-width: 320px) {
    .dragImg img {
        display: none !important;
    }

    .nav-tabs .nav-link {
        font-size: 10px !important;
        padding: 3px !important;
    }

    .Coverimage_dp {
        left: 64% !important;
    }

    .smallWidth {
        width: 31% !important;
    }

    .screenHeight {
        height: unset !important;
        margin-top: 59px !important;
    }

    .display-setting {
        width: 260px !important;
    }

    .timelinescroll {
        padding: 0px 30px !important;
    }

    .font_small {
        font-size: 7px !important;
    }

    .smallDialogBox {
        position: unset !important;
    }

    .icon_f {
        font-size: 13px !important;
    }

    .h6_respons {
        font-size: 9px !important;
    }

    .font_exsmall {

        padding: 1px 0px 0px 2px !important;
    }

    .personImgSize img {
        height: 36px !important;
        width: 36px !important;
    }

    /* .folow-btn {

        padding: 0px 10px 0px 10px !important;
        font-size: 6px !important;

    } */

    .textSmallShow {

        padding-left: 20px;
    }

    .textSmallShow h4 {
        font-size: 13px;
    }
}