.bg-writemsg_messngr{
  background: #E7E8FB;

}
.streamline--container {
    background: #ffffff;
    border-radius: 8px;
}
  .custom_border{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
    .leavechat-btn {
      background: #E7E8FB;
      border-radius: 6px;
      padding: 4px 6px;
      color:#212121;
      font-size: 12px;
      border: none;
    }
    .leavechat-btn  p{
      font-size: 15px;
      color: white;
      padding-left: 5px;
     }
     .leavechat-btn  img{
      height: 20px;
     }
    
    .para-font-s {
      font-size: 12px;
    }
    .chat-font {
      font-size: 11px;
    }
  
    .chat_bar {
      font-size: 12px;
      background: #e7e8fb;
      border-radius: 75px;
      padding: 7px;
      line-height: 12px;
    }
  
    .send__btn{
      background: #267DCF;
  border-radius: 4px;
  font-size: 12px;
  padding:6px 11px 6px 11px;
  font-weight: 500;
  color:white;
    }
  



  
  .img__setting {
    vertical-align: middle;
    height: 40px;
    margin-left: auto;
    object-fit: cover;
  
  }

    
  .img__setting img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  
  .scroll_messages{
      height: 54vh;
      overflow-y: auto;
      overflow-x: hidden;
  }
  
  .bg-writemsg{
      background: #E7E8FB;
      border-radius: 0px 0px 8px 8px;
   
  
    }

     .left-image{
      background: #267DCF;
border-radius: 4px;
width: 100px;
padding: 3px 0px 2px 0px ;
color:white !important

    }
  
    .left-image-two{
      background: #212121;
      color:white !important;
  border-radius: 4px;
  width: 100px;
  padding: 3px 0px 2px 0px ;
  }
  .custom_form_Control{
    border: none !important;
    box-shadow: none!important;
  }
  
  .custom_form_Control:focus{
    border: none!important;
    box-shadow: none!important;
  }
  .liveChatAbsolute{
    position: absolute;
    z-index: 111;
    background: #212121;
    border-radius: 8px;
  }
   
  