.phera{
    background-color: #267DCF;
    color: white;
    width:70%;
}

.Clientinfo{
    background-color: #267DCF;
    color: white;
    width: 100%;
}

.inputcolor {
    width: 100%;
    border: transparent;
    background-color: #F2F2F2;
}
.inputcolors {
    width: 70%;
    border: transparent;
    background-color: #F2F2F2;
}

@media (max-width: 991px) and (min-width: 768px) { 
    .phera{
        width: 100%;
    }
    .inputcolors {
        width: 100%;
    }
    .Clientinfo{
        width: 100%;
    }
    .inputcolor {
        width: 100%;
    }
} 

@media (max-width: 767px) and (min-width: 576px) { 
    .phera{
        width: 78%;
    }
    .inputcolors {
        width: 78%;
    }
    .Clientinfo{
        width: 99%;
    }
    .inputcolor {
        width: 99%;
    }
}

@media (max-width: 575px) and (min-width: 367px) { 
    .phera{
        width: 78%;
    }
    .inputcolors {
        width: 78%;
    }
    .Clientinfo{
        width: 93%;
    }
    .inputcolor {
        width: 93%;
    }
}