.col_adjust {
  display: flex;
  flex-direction: column;
}
.sec_col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.img_s {
  border-radius: 50%;
  /* height: auto; */
  width: 50%;
  height: 41%;
}
.row_div {
  margin-left: 15%;
  margin-right: 10%;
}
/* .ms-4 {
  margin-left: 0rem !important;
} */
/* .ps-4 {
  padding-left: 0rem !important;
} */
/* .pe-4 {
    padding-right: 0!important;
} */
/* g */
.button_padding {
  padding-left: 8% !important;
  padding-top: 32px !important;
  /* border-radius: 0% !important; */
}
.screen_height {
  height: 50vh !important ;
  overflow-y: scroll;
}
.webkit {
  width: -webkit-fill-available;
}
.title {
  color: #7b7b93;
}
.breakword {
  word-wrap: break-word;
}
.odd-background {
  
  background-color:#E4E3E3!important ;
}
.read_btn{
  border-radius: 0px;
  padding: 0px 20px !important;
}
