.btn-application-red {
    background: #EE3B3B;
    color: white;
    font-size: 14px;
    font-weight: 400;
    border: transparent;
    border-radius: 5px;
}
.btns-connectss {
    background-color: #0353A4!important;
    color: white !important;
    width: 12vh !important;
    border: transparent !important;
    border-radius: 7px;
    height: 5vh;
}
.report-icn{
    color: rgb(38, 38, 43);
    font-size: 16px;
}
.btn-1 {
    background-color: rgba(233, 167, 29, 1)!important;
    border: transparent;
    width: 10vh;
    color: white;
    height: 5vh;
}
.btn-application-2{
    background-color: rgba(61, 59, 238, 1)!important;
    border: transparent;
    color: white;
    height: 10%;
}
.btn-application-3{
    background-color: rgba(61, 59, 238, 1)!important;
    border: transparent;
    font-size: 14px;
    color: white;
    width: 22vh;
    height: 5vh;
}
.btn-application-4{
    background-color: rgba(61, 59, 238, 1)!important;
    border: transparent;
    font-size: 14px;
    color: white;
    width: 20vh;
    height: 5vh;
}
.btn-application-5{
    background-color: rgba(61, 59, 238, 1)!important;
    border: transparent;
    font-size: 14px;
    color: white;
    width: 17vh;
    height: 5vh;
}
.btn-2{
    position: absolute !important;
    background-color:  rgba(61, 59, 238, 1)!important;
    color: white !important;
    width: 10%;
    height: 5vh;
}
.btn-3{
    color: white !important;
    background-color:  rgba(61, 59, 238, 1)!important;
}
.Button-4{
    background-color: rgba(233, 167, 29, 1)!important;
    color: white !important;
}
.btn-application-1 {
    background-color: rgba(61, 59, 238, 1)!important;
    border: transparent;
    color: white;
    width: 100%;
    height: 5vh;
}
.Btn-Height{
    height: 5vh;
    background-color: rgba(61, 59, 238, 1)!important;
}
.Restaurant_img {
    border-radius: 50%;
    height: 335px;
}

.BUTTON {
    width: 9% !important;
    border-radius: 26px !important;
}
#controlled-tab-example-tab-tab1
{background: #3d3bee;
    color: white !important;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px !important;
}
@media only screen and (min-width:992px) and (max-width:1399px){

    .transport_img {
        height: 170px !important;
    }
    
} 