.chatboxImg2  {
    height: 37px;
    overflow: hidden;
    object-fit: cover;
}

.chatFriendScroll {
    max-height: 330px;
    overflow-y: auto;
}



.chatBoxUsers {
    position: absolute;
right:0rem;
    top:18rem;

}
.search-input1 {
    width: 100%;
    border-radius: 20px;
    border: 1px solid #B3B3B3 !important;
}