.btns-1{
    width: 65%;
    height: 30px;
}
.check-text{
    font-weight: 500;
    font-size: 13px;
}
.btns-end{
        color: white;
        background-color: #0353A4;
        border: transparent;
}
@media (max-width: 684px) and (min-width: 568px){
    
}