.img_hover_box img{
    width:70%;
margin: auto;
}
.img_hover_box{
    padding: 1rem;
}
.img_hover_box:hover{
    background-color: rgba(134, 188, 224, 0.274);
    padding: 1rem;
    border-radius: 50%;
 
}
.link_bg{
    background-color: rgba(134, 188, 224, 0.274);
    border-radius: 50px;
}