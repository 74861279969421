.drop-abb{
  background-color: #f8f8fd;
  width: 100%;
  overflow-y: auto;
  height: 150px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  border-top: none;
  z-index: 1000;
}





.search-absolute{
  top:0;
  right:0;
}
.MuiSelect-select {
  padding: 2px !important;
}
.category {
  border: 1px solid grey !important;
}

.category option {
  font-size: 13px !important;
}

.btns {
  font-size: 12px;
  font-weight: 400;
}

.icon_width {
  width: 11%;
}

.margin-Boxes {
  margin: 0px 11px;
}

.view_btn-User {
  color: #3d3bee;
  padding: 0px 0px;
  font-size: 12px;
  border: 1px solid;
  border-radius: 11px;
  cursor: pointer;
  width: 85%;
  text-align: center;
  margin: 2px 0px;
}

.w-img-card {
  width: 17px;
}

.stock {
  margin: 0px;
  font-size: 11px;
  color: #3d3bee;
  text-align: center;
  border: 1px solid #3d3bee;
  border-radius: 15px;
}
.relate_div {
  position: relative;
  border: 1px solid darkgrey;
  border-radius: inherit;
}
.absolout_div {
  position: absolute;
  background-color: red;
  color: white;
  padding: 0px 4px;
  border-radius: 2px;
  width: fit-content;
}
.pointer_clsss {
  cursor: pointer;
}
