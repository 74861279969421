.img-3-Background {

   width: 100%;
   height: 250px;
}

.img-3-Background  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-4-scroll {
    max-height: 56vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
}

.profile-bio-scroll-3{
    max-height: 66.4vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 25px;
    margin-left: 20px;
}

.text-color-cv3{
color:#211F28 ;
}