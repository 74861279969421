.compose {
  display: flex;
  align-items: center;
  background: #212121;
  padding: 10px;
  padding-top: 10px;
  margin-bottom: 20px;
  color: white;
  /* width: 190px; */
  height: 45px;
  left: 169px;
  top:0px;
  position: sticky;
  background: #212121;
  border-radius: 5px 0px 0px 0px;
  cursor: pointer;
}
.sidebar {
  height: 100vh;
  /* width: 60%; */
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
 
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}



.openbtn:hover {
  background-color: #444;
}

#main {
  transition: margin-left .5s;
  padding: 16px;
}

.ICON{
  font-size: 30px !important;
  color: white !important;
}
.class-height-leftbar{

  overflow-y: hidden;
  overflow-x: hidden;

}

.compose img {
  margin-right: 10px;
}

.menu-item {
  color: white;
  margin-left: 15px;
  margin-right: 20px;
  width: 20px;
  height: 18px;
}

.act-one{
  color: white;
}

.imt-sets {
  font-weight: 600;
  font-size: 14px;
  line-height: 2px;
  margin-left: 3px;
  color: #FFFFFF;
  margin-bottom: 7px;
}

.imtx-sets {
  font-weight: 600;
  font-size: 11px;
  line-height: 2px;
  margin-left: 3px;
  color: #FFFFFF;
}

.imt-set{
  font-weight: 500;
  font-size: 13px;
  line-height: 10px;
  margin-left: 5px;
  color: #FFFFFF;
}

.dropdown-menu p {
  margin: 0;
  padding: 5px;
  cursor: pointer;
}
.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
  width: 100%;
  text-align: start;
}
  .openbtn:hover {
    background-color: #444;
  }
.imts-sets {
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  margin-left: 3px;
  color: #FFFFFF;
}
.colr-one {
  background: #267dcf;

}

.dropdown-options {
  color: #212121;
}

.custom-dropdown {
  margin-left: 54px;
  margin-bottom: 11px;
}

.atd-cla{
color: white;
border: 5px;
}

.menu-item-cursor{
  cursor: pointer;
}
.mob-version{
  display: none;
}
.desktop-version{
  display: block;
}
.leftScroll{
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media (max-height: 900px) {
  .colr-one {
    height: auto;
    
  }
.Colr{
  color: white;
}
}
@media (max-width: 991px) and (min-width: 320px){
  .mob-version{
    display: block!important;
  }
  .desktop-version{
    display: none!important;
  }


}