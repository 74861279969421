.MODLPIC{
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    width: 15%;
  
}
.MODLTXT{
   color:  #267DCF;
   cursor:  pointer ;
}
.span-color{
    color: #003559;
}
.MODL{
    height: 100px;
}