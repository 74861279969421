.Profile_dp {
    position: absolute;
    top: -50px;
    border: 6px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    vertical-align: middle;
    width: 105px;
    height: 105px;
    border-radius: 50%;
    background: #3d3bee;
    object-fit: cover;
    left: 50px;
    overflow: hidden;
    
}

.P_camera {
    position: absolute;
    left: 120px;
    top: 20px;
    background: #ffffff;
    border-radius: 50%;
}

.profile_img2{
    width: 105px;
    height: 105px;
    object-fit: cover;
}

