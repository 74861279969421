.View_section {
    background: #267dcf;
    border: 1px solid #267dcf;
    border-radius: 8px;
}

.img_view_section img {
    width:100%;
}
.deleteChatRoom{
    background-color: #212121;
    color: white;
    padding: 4px 35px;
    font-size: 14px;
}
.chatRoomDescription{

}
.View_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.paragraph-view h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    color: #FFFFFF;
}

.paragraph-view p {
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.10rem;
    color: #FFFBFB;
}

.Inner_people_view {
    background: #267dcf;
    border: 1px solid #267dcf;
    border-radius: 8px;
}


.heading-flex {
    display: flex;
    justify-content: center;

  
}

.heading-flex h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    text-align: justify;
    color: #FFFFFF;
    padding: 8px 0px;
}

.Btn_flex_section {
    display: flex;
    justify-content: space-between;
}

.btn-1-search {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    color: #0FC2C0;
    font-size: 14px;
    padding: 5px 30px 5px 30px;
}

.btn-33-AddMembers {
    background: #212121!important;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    

}

.main-flex-profile-people {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid #C4C4C4;
    border-top: 1px solid #C4C4C4;
}

.prfile-text-flex {

    display: flex;
    justify-content: space-between;
}

.Admin-online {
    display: flex;
    justify-content: space-evenly;

   
}

.Admin-online  p {
    background: #FFFFFF;
    border-radius: 11px;
    padding: 0px 9px;
    margin: 0px 0px;
    position: relative;
    left: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-align: justify;
    color: #212121;
}

.img-online {
    position: relative;
    bottom: 11px;
    left: 14px;
}

.admin_name {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
    color: #FFFFFF;
}


.chats-show-profile {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 12px;
    text-align: justify;

    color: #FFFFFF;
}

.main-flex-profile-people-2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #C4C4C4;
    
}

.flex-people-sextion-2 {
    display: flex;
}

.People_flex_section_Main {
    display: flex;
    padding: 8px 19px 1px 19px;
    justify-content:space-evenly;
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
}

.Padding_srction_people {
    padding: 0px 0px 0px 14px;
}

.img_position_People {
    position: relative;
    bottom: 8px;
    left: 42px;
}

.Admin_name_Section {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    text-align: justify;
    color: #FFFFFF;

}

.Chats-show-profile {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    text-align: justify;
    color: #FFFFFF;

}
.People_Main_Scrool{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 40vh;
}
.img_boy_1{
    width:42px;
}
.input-group-addon{
    border-radius: 5px;
}