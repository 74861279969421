.cv-modal-btn1 {
  background-color: #ee3b3b;
  padding: 2px 30px 2px 30px;
  border: transparent;
  border-radius: 3px;
  color: white;
}

.cv-modal-btn2 {
  background-color: #0353a4;
  padding: 5px 36px 6px 31px;

  border: transparent;
  border-radius: 3px;
  color: white;
}

.cv-modaal-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 3px solid #0353a4;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.cv-modal-head {
  background-color: #0353a4;
}


.modal-header .btn-close {
  background-color: #ee3b3b;
}