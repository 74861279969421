.messageArea{
    overflow-y: auto;
    height: 200px;
}
.leftMessage{
    background: #e2e1ff;
    border-radius: 6px;
    margin: 4px 0px 8px 5px;
}
.subbox_absolute{
    position: absolute;
    left:0rem;
    top: 28rem;
}
.sm-btn {
    background: #3d3bee;
    border: transparent;
    border-radius: 3px;
    padding: 3px 17px;
    font-size: 13px;
    color: #ffffff;
}