

/* Media query for small screens (e.g., smartphones) */
@media screen and (max-width: 768px) {
    .sales {
        width: auto;
        height: auto;
    }
}

/* Media query for medium screens (e.g., tablets) */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .sales {
        width: auto;
        height: auto;
        font-size: medium;
    }
}


