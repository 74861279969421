


.chatroomImg{
    width:100%;
    height:550px;
}




.microphoneBackground {
    background: #E7E8FB;
    border-radius: 50px;
    padding: 8px 14px; 
}

.liveClose {
    background: #EE3B3B;
    border-radius: 6px;
    color: white;
    padding: 4px 20px;
}

.inviteChatroomButton {
    background: #3D3BEE;
    border-radius: 6px;
    display: flex;
    padding: 4px 20px; 
}

.fa-user-plus {
    color: white;
    font-size: 13px;
}

.inviteChatroomButton p {
    color: white;
}
.chatTime p{
    color: white;
    font-size: 20px;
}
.iconsBackgroundVoice{
    background: #E7E8FB;
    border-radius: 50px;
    padding: 10px 15px;
    color: #3641B7;

}
.iconsBackgroundVideo{
    background: #E7E8FB;
    border-radius: 50px;
    padding: 12px 15px;
    color: #0FC2C0;
    font-size: 18px;
}