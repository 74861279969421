.btn-lg {
  width: 100% !important;
  border-radius: 6px;
  padding: 0.3rem 0.75rem !important;
  border: 0px;
}

.btn-dash-sm {
  width: 111px;
  height: 25px;
  font-size: 14px !important;
  border-radius: 4px;
  font-weight: 500;
  border: transparent;
}

.rateme {
  background: #faaf3a;
  border-radius: 6px;
  border: transparent;
  padding: 4px 24px;
}

.fHIrZs {
  display: none !important;
}


.cancle-btn {
  background: #ee3b3b;
  border-radius: 4px;
  color: white;
  padding: 4px 15px;
  font-size: 12px;
  border: transparent;
}

.post-btn {
  background: #3d3bee;
  border-radius: 4px;
  color: white;
  padding: 4px 15px;
  font-size: 12px;
  border: transparent;
}

.btn-application {
  background: #3d3bee;
  color: white;
  font-size: 12px;
  font-weight: 400;
  border: transparent;
  border-radius: 5px;
  padding: 3px 10px;
}

.btn-applications {
  background: #3d3bee;
  color: white;
  font-size: 13px;
  font-weight: 400;
  border: transparent;
  border-radius: 5px;
}

.btns-applicationss {
  background: #3d3bee;
  color: white;
  font-size: 12px;
  font-weight: 400;
  border: transparent;
  border-radius: 5px;
  /* width: 10%; */
}

.btn-working {
  background: #faaf3a;
  color: white;
  font-size: 14px;
  font-weight: 400;
  border: transparent;
  border-radius: 5px;
  padding: 2px 10px;
}

.btn-avaliable {
  background: #3d3bee;
  color: white;
  font-size: 14px;
  font-weight: 400;
  border: transparent;
  border-radius: 5px;
  padding: 2px 10px;
}

.btn-primary {
  background-color: #0353a4;
  color: white;
  border: transparent;
  font-size: 12px;
}

.follow-connt {
  color: white;
  border: 1px solid #0353a4;
  font-size: 9px;
  font-weight: 600;
  border-radius: 8px;
  padding: 0px 25px 0px 25px;
  cursor: pointer;
  background-color: #0353a4;
}

.viewshop {
  color: #3d3bee;
  border: 1px solid #0353a4;
  font-size: 12px;
  border-radius: 15px;
  padding: 1px 15px 1px 15px;
  cursor: pointer;
  background: transparent;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.transport-btn {
  padding: 2px 15px 2px 15px;
  border: transparent;
}

.btn-blue-sm {
  background: #0353a4;
  border-radius: 4px;
  border: transparent;
  color: white;
  font-size: 14px;
  padding: 9px 0px;
  width: 115px;
}

.btn-red-sm {
  background: #d62828;
  border-radius: 4px;
  border: transparent;
  color: white;
  font-size: 14px;
  padding: 9px 0px;
  width: 115px;
}

.btn-outline-1 {
  border: 1px solid #dc3545 !important;
}

.share_btton {
  border: 1px solid #0353a4;
  background-color: transparent;
  font-size: 11px;
  color: #0353a4;
  border-radius: 6px;
  padding: 1px 10px 1px 6px;
}

.btn-silver {
  background: #7B7B93;
  border: transparent;
  font-size: 12px;
}