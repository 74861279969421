.shops_buying_h::-webkit-scrollbar {
    display: none;
  }
  
  .right-to-left-modal .modal-dialog {
    transform: translateX(120%) !important;
    animation: slide-in .6s forwards !important;
    width: 24.5rem;
  }
  
  @keyframes slide-in {
    100% {
      transform: translateX(0) !important;
    }
  }
  
  
  .modal-height {
  
    height: 222px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 4px;
    background-color: #EDEFF4;
  
  
  }
  